.chat-theme {
  color: var(--text-primary-color);
}

.chat-section {
  height: calc(100vh - 110px);
}

.chat-left-part {
  height: calc(100vh - 110px);
  /* background-color: gray; */
}

.chat-right-part {
  background-color: #0d0d0d;
  /* height: calc(100vh - 110px); */
}

/* .chat-left-header {
  height: 100px;
} */

.chat-label {
  font-size: 20px;
  font-weight: 600;
}

.chat-new-icon {
  height: 30px;
  width: 30px;
  border: solid 1px black;
  border-radius: 50%;
  background-color: var(--background-secondary-color);
}

.chat-search-box {
  position: relative;
}

.chat-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}

.chat-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
}

.chat-search-input:focus {
  outline: none;
}

.chat-list {
  height: 65vh;
  overflow-y: scroll;
}

.chat-list-user {
  position: relative;
  border-bottom: solid 1px var(--background-secondary-color);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  color: var(--text-primary-color);
}

.selected-chat {
  background-color: var(--background-secondary-color);
}

.chat-profile-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat-unread-count {
  position: absolute;
  right: 15px;
  top: 35px;
  background-color: var(--portal-theme-primary);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  color: var(--text-secondary-color);
}

.chat-unread-count-style {
  background-color: var(--portal-theme-primary);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  color: black;
}

.chat-last-time {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 10px;
  font-weight: 400;
  color: var(--text-secondary-color);
  width: 100%;
}

.chat-last-message {
}

.chat-text-message {
  background-color: #1c1c1c;
}

.chat-incoming {
  background-color: #e4e6eb;
  color: black;
  border-radius: 10px 10px 10px 0px;
  max-width: 85%;
  /* max-width: 80%;
  min-width: 100px; */
  font-size: 14px;
  margin: 5px 10px;
  position: relative;
  padding: 5px 15px 15px 10px;
  overflow-wrap: break-word;
  min-width: 130px;
}

.chat-outgoing {
  background-color: rgb(0 132 255);
  color: white;
  border-radius: 10px 10px 0px 10px;
  max-width: 85%;
  font-size: 14px;
  margin: 5px 10px;
  position: relative;
  padding: 5px 15px 15px 10px;
  overflow-wrap: break-word;
  min-width: 130px;
}

.chat-incoming a,
.chat-outgoing a {
  color: var(--portal-theme-primary);
}

.chat-incoming a:hover,
.chat-outgoing a:hover {
  /* color: var(--portal-theme-primary); */
  text-decoration: underline !important;
}

.chat-message-space {
  padding-right: 100px;
}

.message-time {
  position: absolute;
  bottom: 2px;
  right: 10px;
  font-size: 10px;
}

.message-duration {
  position: absolute;
  bottom: 2px;
  left: 11px;
  font-size: 10px;
}

.control-dots .chat-message-dots {
  margin-top: 15px;
  visibility: hidden;
}

.control-dots:hover .chat-message-dots {
  margin-top: 15px;
  visibility: visible;
}

.chat-send-message-wrapper {
  /* position: absolute !important; */
  /* bottom: -10px; */
  /* height: 60px; */
  width: 100%;
  padding: 5px 10px;
  background-color: var(--background-primary-color);
  /* border-top: solid 1px var(--portal-theme-primary); */
  border-radius: 7px 7px 0px 0;
}

.chat-image {
  position: relative;
}

.chat-image .cross {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
  color: var(--portal-theme-primary);
  background-color: var(--background-secondary-color);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.chat-preview-image {
  height: 80px;
  width: 100px;
  border-radius: 5px;
  object-fit: cover;
}

.chat-send-input {
  background-color: #262626;
  width: 100%;
  padding: 9px 15px;
  padding-right: 30px;
  padding-left: 37px;
  border-radius: 25px;
  color: white;
  border: none;
}

.chat-send-input:focus {
  outline: none;
}

.user-profile {
  position: relative;
}

.user-profile .online {
  height: 8px;
  width: 8px;
  background-color: #057642;
  border-radius: 50%;
  position: absolute;
  bottom: 3%;
  right: 3%;
}

.user-profile .offline {
  height: 8px;
  width: 8px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
  position: absolute;
  bottom: 3%;
  right: 3%;
}

.user-profile .user-level {
  z-index: 1;
  height: 17px;
  width: 17px;
  position: absolute;
  bottom: -6%;
  left: -8%;
}

.chat-profile-name-padding {
  padding-right: 110px;
}

/* .user-profile .offline {
  display: none;
} */

/* User box css */

.user-box-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--background-secondary-color);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  z-index: 20;
}

.user-chat-box-body {
  position: relative;
  overflow: auto !important;
  height: 60vh;
  /* padding-bottom: 30px; */
  padding-top: 10px;
  transition: height 0.3s;
}

.theme-bottom-border {
  border-bottom: solid 1px var(--portal-theme-primary);
}

.user-box-header {
  padding: 0px 15px;
  height: 50px;
  border-bottom: solid 1px var(--portal-theme-primary);
  margin-bottom: 1px;
  cursor: pointer;
}

.user-box-header:hover {
  background: rgba(158, 163, 161, 0.08);
}

.hover-effect {
  cursor: pointer;
}

.hover-effect:hover {
  background: rgba(158, 163, 161, 0.08);
}

.user-box-heading {
  font-size: 14px;
  font-weight: 600;
}

.user-box-body-expand {
  overflow-x: scroll;
}

/* ------------ */
/* User chat box css */

.user-chat-box-header {
  padding: 0px 15px;
  height: 55px;
  border-bottom: solid 1px var(--portal-theme-primary) !important;
  cursor: pointer;
  background-color: transparent;
}

.user-chat-box-header:hover {
  background: rgba(158, 163, 161, 0.08);
}

.user-chat-box-wrapper {
  position: fixed;
  bottom: 0;
  right: 310px;
  z-index: 1020;
  width: 400px;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--background-secondary-color);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
}

.chat-box-cross-icon {
  cursor: pointer;
}

.chat-box-cross-icon:hover {
  color: red;
}

/* ----------------- */
/* -------Right Part---------- */

.chat-right-wrapper {
  position: relative;
  height: 100%;
}

.chat-right-head {
  height: 70px;
  /* background-color: var(--background-primary-color); */
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--background-secondary-color);
}

.chat-right-bottom {
  /* position: absolute; */
  bottom: 0px;
  width: 100%;
}

/* --------------------------- */

.no-chat-wrapper {
  height: 60vh;
}

.no-chat-div {
  background-color: var(--background-secondary-color);
  padding: 30px;
  border-radius: 50%;
}

.no-chat-image {
  font-size: 100px;
  color: var(--portal-theme-primary);
}

.chat-formatters-box {
  padding: 0;
  width: 100%;
  height: 0;
  margin-bottom: 0;
  border-radius: 15px 15px 0 0;
  transition: height 0.3s;
}

.chat-formatters-border {
  border-top: 1px solid #6a6a6a99;
  border-left: 1px solid #6a6a6a99;
  border-right: 1px solid #6a6a6a99;
}

.chat-image-preview-box {
  background-color: var(--background-secondary-color);
  padding: 7px 10px;
}

.border-with-image {
  border-top: 1px solid #6a6a6a99;
  border-radius: 0 0 18px 18px;
}

.formatter-icon {
  height: 30px;
  width: 30px;
  /* background-color: var(--sidebars-background-color); */
  border-radius: 50%;
  text-align: center;
  padding-top: 3px;
  margin-left: 5px;
  cursor: pointer;
}

.show-hide-chat-formatter {
  position: absolute;
  bottom: 15px;
  right: 10px;
  cursor: pointer;
}

.upload-image-icon {
  background-color: transparent;
}

.chat-send-message-wrapper .emoji-picker-button-creat-post {
  bottom: 2px !important;
  left: 1px !important;
  top: auto;
  background-color: transparent;
}

.general-live-chat-list .action-date-time {
  font-size: 11px;
  color: var(--text-secondary-color);
}

.general-live-chat-list .comment-like.action-box:hover {
  text-decoration: underline;
}

.pinned-comments-list.general-live-chat-list {
  border-bottom: 1px solid var(--portal-theme-primary);
}

.general-live-chat-list .see-less-more {
  color: var(--portal-theme-primary);
  cursor: pointer;
  white-space: nowrap;
}

.general-live-chat-list .inception .affirmation-dots .remove-border {
  height: 20px;
  right: 0px;
  top: 0px;
  width: 20px;
}

@media (max-width: 540px) {
  .user-chat-box-wrapper {
    right: 0;
  }

  .simplebar-content-wrapper {
    height: 70vh;
  }
  .for-mobile {
    height: 100% !important;
  }
  .chat-incoming audio,
  .chat-outgoing audio {
    width: 240px !important;
  }
}

.messages-delivered {
  .MuiDivider-wrapper {
    display: inline-block;
    padding-left: calc(8px * 1.2);
    padding-right: calc(8px * 1.2);
    color: #e4c073;
  }
}

.messages-delivered::before,
.messages-delivered::after {
  width: 100%;
  border-top: 1px solid rgba(145, 158, 171, 0.24);
  border-color: #e4c073;
}
