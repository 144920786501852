/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");

/* black and Goldenen theme */
:root {
  /* Main theme color */
  --portal-theme-primary: #e4c073;
  --portal-theme-primary-filter-color: invert(74%) sepia(96%) saturate(396%)
    hue-rotate(332deg) brightness(101%) contrast(93%);
  --portal-theme-secondary: rgba(175, 151, 102, 0.199);
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);
  /* Main background colors */
  --background-primary-color: black;
  --background-secondary-color: black;
  /* Sidebar Properties */
  --sidebars-text-color: white;
  --sidebars-background-color: #1d1c1d;
  /* Popup / Popover properties  */
  --popup-background-color: black;
  --popup-text-color: white;
  /* Element properties */
  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: #fff;
  --input-background-secondary: #fff;
  /* Button Properties */
  --button-background-color: #1c1c1c;
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
  --datepicker-selected-color: #fff;
  --input-border-color: rgba(145, 158, 171, 0.32);
}

/*  */
/*  */
/* //////////////////////////////////////////////customize scrollbar */
/*  */
/*  */

/* ------------------------- */

/*  in chrome this works */
/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
/* HINA KHAN IN DARK MODE CONVERSION STARTS FROM HERE */
body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--background-primary-color) !important;
  color: var(--text-primary-color) !important;
}

.secondary-background {
  background-color: var(--background-secondary-color);
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  color: var(--text-primary-color);
}

.w-85 {
  width: 85%;
}

.css-2tnm32.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: var(--portal-theme-primary);
}

::-webkit-media-controls-enclosure {
  background-color: var(--portal-theme-secondary);
}

.css-18dh5wg-MuiTypography-root {
  color: var(--text-primary-color) !important;
}

.css-6obpvq-MuiTypography-root-MuiLink-root {
  color: var(--text-primary-color);
}

/* calender colors */
.MuiCalendarPicker-root {
  background-color: var(--popup-background-color) !important;
  color: var(--input-text-color);
}

.MuiCalendarPicker-root button {
  background-color: transparent !important;
  color: var(--input-text-color);
}

.MuiCalendarPicker-root .Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: var(--datepicker-selected-color);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: var(--portal-theme-primary-filter-color);
  cursor: pointer;
}
.popover-counters {
  display: flex;
  margin-top: 10px;
}

.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  display: none !important;
}

.MuiPickersToolbar-root .css-xzqsgo {
  display: none !important;
}

.css-dplwbx-MuiPickersCalendarHeader-label {
  color: #ccc5c5;
}

/* calender colors end */

/* card background */
/* .css-1dg1xmf-MuiPaper-root-MuiCard-root {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
} */
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-ju84cc-MuiButtonBase-root-MuiMenuItem-root,
.css-1h0kuck-MuiButtonBase-root-MuiButton-root {
  color: var(--text-primary-color);
}

.css-1fuveia-MuiPaper-root-MuiPopover-paper,
.css-13pz97u {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-1uc7r6i {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

/* .css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  color: var(--sidebar-normal-color);
} */
.css-1ti1ykt-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-15ez4hi-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.css-alxk9h-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--text-primary-color);
}

.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input,
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}

/* fieldset {
  border-color: var(--portal-theme-secondary) !important;
}
fieldset:focus {
  border-color: var(--portal-theme-primary) !important;
} */
.svg-color svg,
.MuiCalendarPicker-root svg {
  color: var(--portal-theme-primary);
}

/* calendar selected Color */
.css-14ewge7.Mui-selected,
.css-14ewge7.Mui-selected:hover,
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

.css-1qwrva7-MuiButtonBase-root-MuiListItemButton-root:before {
  background-color: var(--portal-theme-primary);
}

/* calender selected hover */
.css-2tnm32.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary);
}

/* .css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--portal-theme-primary);
} */
.css-1w4063c-MuiTypography-root,
.css-rq00hd-MuiTypography-root {
  cursor: pointer;
}

.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}

.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: var(--portal-theme-primary);
}

.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  /* background-color: var(--portal-theme-primary); */

  color: var(--background-primary-color);
}

.css-rxrcxl-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}

.css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent;
}

.css-1gxhmaf-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}

.css-x6t0nh-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary) !important;
}

.css-733kmc-MuiStack-root {
  background-color: var(--sidebars-background-color);
}

.css-jjtu05 {
  background-color: var(--sidebars-background-color);
}

.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  box-shadow: none;
}

.css-1nuss9t {
  color: var(--text-primary-color);
}

.css-40mfy8-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}

.css-lvwv31 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}

.css-pt151d {
  color: var(--portal-theme-primary);
}

.css-yzm7vx,
.css-184p1yy,
.css-12ff9sa,
.css-12ff9sa {
  color: var(--text-primary-color);
}

.review-card {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.css-k7695x-MuiPaper-root-MuiAppBar-root {
  background-color: var(--background-primary-color);
  /* z-index: 11111 !important; */
}

.css-3ioe56 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-1hes0mo:hover {
  color: var(--portal-theme-primary);
}

.css-66zhem {
  background-color: var(--background-primary-color);
}

.css-ze1xs7 {
  color: var(--portal-theme-primary) !important;
}

.css-f0u5qy {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-12ff9sa {
  color: none;
}

.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}

.css-r13mc8 {
  color: var(--portal-theme-primary);
}

.new-memories {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}

.css-yxnldu {
  background-color: var(--sidebars-background-color);
}

/* .css-i4bv87-MuiSvgIcon-root {
  color: var(--portal-theme-primary);
} */
.css-1bfvxec {
  color: var(--text-primary-color);
}

.css-yzm7vx {
  color: var(--text-primary-color);
}

.css-vb81xk-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--text-primary-color);
}

.css-zuwxiq {
  color: var(--text-primary-color);
}

.css-1v6giby {
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
}

.css-13d5z5v {
  color: var(--background-primary-color);
  /* background-image: url(../images/btn-img.png); */
}

.css-13d5z5v:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
}

.css-q6wzhr {
  color: var(--portal-theme-primary);
}

.css-zuwxiq {
  color: var(--text-primary-color);
}

.css-2300kw {
  color: var(--text-primary-color);
}

.program-lock-icon .css-vubbuv {
  fill: var(--portal-theme-primary);
}

.css-vubbuv {
  fill: var(--portal-theme-primary);
}

.dashboard-welcome-description h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.css-4cxmk4,
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}

.card {
  background-color: var(--background-secondary-color);
}

.modal-theme {
  background-color: var(--sidebars-background-color);
}

.orderdes p {
  color: gray;
  font-size: 12px;
  margin-top: 7px;
}
.item-quantity {
  border: 1px solid var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  max-width: 92px;
  padding: 0;
}
.item-quantity span {
  text-align: center;
  width: 30px;
}
.item-quantity input {
  background-color: initial;
  border-bottom: 0;
  border-left: 1px solid var(--portal-theme-primary);
  border-left: 1px solid var(--portal-theme-primary);
  border-right: 1px solid var(--portal-theme-primary) !important;
  border-right: 1px solid var(--portal-theme-primary) !important;
  border-right: none;
  color: #fff;
  color: var(--input-text-color);
  font-size: 13px;
  outline: none;
  text-align: center;
}
.icon-container-card {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-size: 15px;
  text-align: center;
}
.css-1xjb83h {
  color: var(--text-primary-color);
}

.css-rmquh4-MuiButtonBase-root-MuiChip-root {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
}

.css-1tir071-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}

.css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}

.css-muvvuh {
  color: var(--text-primary-color);
}

.transaction-screen {
  background-color: var(--background-primary-color);
}

.css-2300kw.Mui-disabled {
  color: var(--text-primary-color);
}
.item-quantity-diabled {
  background-color: #4c4a4a;
  cursor: not-allowed !important;
  border-radius: 4px;
}
.css-1tc40pv {
  color: var(--input-text-color);
}

/* .css-ijridm-MuiTypography-root {
  color: var(--portal-theme-primary);
} */
.css-3ioe56.Mui-expanded:last-of-type {
  background-color: var(--sidebars-background-color);
}

/* HINA KHAN IN DARK MODE CONVERSION ENDS HERE */

h1 {
  font-size: 40px;
  color: var(--portal-theme-primary);
}

h2 {
  font-size: 30px;
  color: var(--portal-theme-primary);
}

h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem !important;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

.normal-text {
  font-size: 14px;
  font-weight: 500;
}

.text-secondary {
  color: var(--text-secondary-color) !important;
}

a:hover {
  text-decoration: none !important;
  color: var(--portal-theme-primary);
}

legend {
  float: unset;
}

.circular-progress {
  margin-left: 50%;
  margin-top: 20%;
}

.text-area-task {
  background-color: var(--input-background-secondary);
  outline: none;
}

.inputs-fields {
  background-color: var(--input-background-primary);
  /* background-color: green; */
}

.form-control:focus {
  background-color: var(--textarea-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}

.form-control {
  border: none;
  color: var(--text-primary-color);
}

.programm-card {
  background-color: var(--background-secondary-color);
}

.programm-card h3 {
  color: var(--portal-theme-primary);
}

.programm-card p span {
  color: var(--text-secondary-color);
}

/* portal-wrapper */
.portal-wrapper {
  padding: 30px 0;
}

.portal-wrapper h2,
.services-testimonial-wrapper h2 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 1px !important;
  color: #fff !important;
  line-height: 1.1;
}

.portal-member h2 {
  line-height: 1.3;
}

.portal-card {
  background: linear-gradient(113.76deg, rgba(0, 0, 0, 0) 0%, #151a21 100%);
  border: 1px solid rgba(181, 157, 78, 0.5);
  padding: 20px 20px 25px;
  border-radius: 6px;
}

.bg-card-black {
  background: #1e2020;
  border-radius: 12px;
  padding: 25px 20px;
}
.quantity {
  color: var(--portal-theme-primary);
  /* font-size: 16px; */
}
.signature-style canvas {
  border: 1px solid #000;
  width: 100% !important;
}

.clearButton {
  color: #fff;
  background-color: #333333;
  width: 60px;
  padding: 2px 5px;
  text-align: center;
  margin-bottom: 5px;
  border-radius: 3px;
  outline: none !important;
  border: none !important;
}
.sigCanvas {
  background-color: white;

  border-radius: 10px;
  color: white;
}
.bg-card-yellow {
  background: #b59d4e;
  border-radius: 12px;
  padding: 25px 20px;
  color: #151717;
}
.sign-Button {
  font-size: 12px;
  margin-top: 17px;
  text-decoration: underline;
  color: var(--portal-theme-primary);

  cursor: pointer;
}
.icon-container-shop {
  position: absolute;
  bottom: 15px;
  width: 90%;

  color: var(--portal-theme-primary);
}
.warning-icons {
  width: 31px;
  position: absolute;
  right: 2%;
  top: 10px;
  cursor: pointer;
}
.bg-card-black h5 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}

.bg-card-black h5 > span {
  font-size: 14px;
  font-weight: 500;
  color: #ccc9c4;
}

.bg-card-black h6 {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: start;
  justify-content: start;
  position: relative;
  margin-bottom: 30px;
}

.bg-card-black h6:before {
  content: "";
  width: 15%;
  height: 1.2px;
  position: absolute;
  top: 25px;
  transform: rotate(360deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #b59d4e,
    rgba(0, 0, 0, 0)
  );
}

.bg-card-yellow h5 {
  color: #151717;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.bg-card-yellow h5 > span {
  font-size: 14px;
  font-weight: 500;
  color: #151717;
}

.bg-card-yellow h6 {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: start;
  justify-content: start;
  position: relative;
  margin-bottom: 30px;
}

.bg-card-yellow h6:before {
  content: "";
  width: 15%;
  height: 1.2px;
  position: absolute;
  top: 25px;
  transform: rotate(360deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #000000,
    rgba(0, 0, 0, 0)
  );
}

.faq-portal-list {
  margin-bottom: 0px;
  padding-left: 10px;
}

.faq-portal-list li {
  display: flex;
  align-items: center;
}

.faq-portal-list li > span {
  padding-left: 12px;
  font-size: 12px;
  color: #ccc9c4;
}

.faq-portal-list li > i {
  color: #bc9c22;
}

.faq-portal-list li > span,
.faq-portal-list li > i {
  padding-top: 14px;
}

.btn-portal button {
  background: #b59d4e;
  color: #151717;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 30px;
  position: absolute;
  bottom: -25px;
  border: none;
  outline: none;
}

.btn-btn-portal {
  padding: 14px 35px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
  transition: 0.5s;
  background: #b59d4e;
  color: #000000;
  border: 1px solid #b59d4e;
}

.btn-btn-portal:hover {
  color: #b59d4e;
  border: 1px solid #515151;
  background: transparent;
}

@media (max-width: 525px) {
  .portal-card {
    padding: 20px 20px 35px;
  }
  .question-buttons button {
    padding: 10px 25px;
  }
}

/* portal-banner */
.portal-banner {
  padding: 60px 0 80px;
}

.portal-card-linear {
  background: linear-gradient(
      89.74deg,
      #151717 0.23%,
      rgba(21, 23, 23, 0) 75.85%
    ),
    radial-gradient(
      75.75% 168.25% at 100% 84.87%,
      rgba(181, 157, 78, 0.2) 0%,
      rgba(21, 23, 23, 0) 100%
    );
  border-radius: 0px 24.4131px 24.4131px 0px;
  padding: 40px 40px;
  height: auto;
  font-size: 16px;
}

.portal-card-linear h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.portal-card-linear p {
  color: #ccc9c4;
  font-weight: 400;
}

.video-portal-border {
  border-radius: 10px;
  border-width: 2px;
  padding: 2px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(181, 157, 78, 0.5)
  );
}

.video-portal-border iframe {
  border-radius: 10px;
  width: 100%;
  background: #000000;
}

/* =========== services-testimonial-wrapper ============== */
.services-testimonial-wrapper {
  padding: 100px 0 0px;
  position: relative;
}

.center-slider .slick-current.slick-active .content .arrow {
  font-size: 30px;
}

.arrowStyle {
  position: relative;
  width: 45px;
  height: 45px;
  color: #bc9c22;
  border: 1px solid #b59d4e;
  line-height: 40px;
  font-size: 18px;
  margin-left: 15px;
  border-radius: 50px;
  background-color: transparent;
  transition: all 500ms ease;
  text-align: center;
}

.arrowStyle:hover {
  color: #fff;
  background-color: #b59d4e;
}

.owl-nav {
  position: absolute;
  right: 9%;
  top: 14px;
  text-align: right;
}

.style {
  font-size: 17px !important;
}

.inner-div-home {
  background: linear-gradient(113.76deg, rgba(0, 0, 0, 0) 0%, #151a21 100%);
  padding: 50px 45px;
  border: 1.19468px solid rgba(181, 157, 78, 0.5);
  opacity: 0.9;
  border-radius: 8px;
  margin: 20px 12px;
  position: relative;
}

.inner-div-home::before {
  content: "";
  position: absolute;
  top: 14.4rem;
  left: 2rem;
  width: 0;
  height: 0;
  border-top: 0.825rem solid transparent;
  border-right: 0.925rem solid #b59d4e;
  border-bottom: 0.825rem solid transparent;
  z-index: 1;
  transform: rotate(270deg);
}

.inner-div-home::after {
  content: "";
  position: absolute;
  top: 14.4rem;
  left: 2rem;
  width: 0;
  height: 0;
  border-top: 0.825rem solid transparent;
  border-right: 0.925rem solid #151717;
  border-bottom: 0.825rem solid transparent;
  z-index: 2;
  transform: rotate(270deg);
}

.slider-home-img {
  position: absolute;
  top: -19px;
  left: 30px;
  z-index: 10;
}

.card-img-home {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.card-img-para h6 {
  font-weight: 600;
  color: #fff;
}

.card-img-para p {
  margin-bottom: 0;
  font-size: 14px;
  color: #ccc9c4;
}

.home-slider-block p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-bottom: 0;
  color: #ccc9c4;
}
.answer-ticket {
  background-color: #007bff;
  color: white;
  width: 80px;
}

.solved-ticket {
  background-color: #28a745;
  color: white;
  width: 80px;
}

.trash-ticket {
  background-color: #ff0000;
  color: white;
  width: 80px;
}

.pending-ticket {
  background-color: #ffc107d9;
  color: white;
  width: 80px;
}
.home-slider-block p:before {
  content: "";
  width: 20%;
  height: 1px;
  position: absolute;
  left: -14px;
  transform: rotate(90deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #b59d4e,
    rgba(0, 0, 0, 0)
  );
}

@media only screen and (max-width: 768px) {
  .owl-nav {
    position: absolute;
    right: 6%;
    top: 0px;
    text-align: right;
  }

  .inner-div-home {
    padding: 50px 10px 50px 30px;
  }

  .inner-div-home::before,
  .inner-div-home::after {
    position: absolute;
    top: 16.6rem;
  }

  .home-slider-block p:before {
    left: -7px;
  }
}

@media only screen and (max-width: 540px) {
  .owl-nav {
    position: absolute;
    right: 6%;
    top: 0px;
    text-align: right;
  }
}

@media (max-width: 414px) {
  .owl-nav {
    position: absolute;
    right: 1%;
    top: 0px;
    text-align: right;
  }

  .arrowStyle {
    width: 35px;
    height: 35px;
    line-height: 33px;
  }

  .style {
    font-size: 14px !important;
  }
}

/* End portal banner */

/* MUI Card */
.mui-custom-card {
  background-color: var(--background-secondary-color);
}

.mui-custom-card h3 {
  color: var(--portal-theme-primary);
}

.mui-custom-card p span {
  color: var(--text-secondary-color);
}

.mui-custom-card-content {
  padding: 15px 15px 20px 15px;
}

.mui-custom-card-description {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}

.mui-card-button {
  fill: var(--portal-theme-primary);
  top: 45%;
  left: 22%;
  font-size: 14px;
  position: absolute;
  padding: "10px 20px";
}
/* .mui-card-button-program {
  left: 40% !important;
} */

.wider-card .mui-card-button {
  left: 45%;
}

.mui_gray_description {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.mui-custom-card-dots {
  z-index: 10 !important;
}

.mui-custom-card-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.card_lower_title_description {
  margin-top: 16px !important;
  margin-bottom: 0 !important;
  color: #fff;
}

.side-image-mui-card {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.side-image-mui-card h4 {
  color: var(--portal-theme-primary);
}

/* MUI Card Ended*/

.lesson-card h4 {
  color: var(--portal-theme-primary);
}

.textarea-color {
  background-color: var(--textarea-color);
}

.textarea-block textarea {
  color: var(--text-primary-color);
}

.simplebar-wrapper {
  height: 333px;
  overflow: hidden;
}

.simplebar-content-wrapper {
  overflow: scroll;
  background-color: var(--sidebars-background-color);
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

textarea:hover {
  box-shadow: none;
}

.custom-popover-icon {
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: var(--portal-theme-primary) !important;
}

.custom-popover li {
  z-index: 10;
  font-size: 12px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
}

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.diray-title h4 {
  font-weight: 400 !important;
}

.dynamite-diary .heading {
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  color: white;
}

.MuiAppBar-root {
  background-color: black;
}

.diary-first-heading {
  color: #ccc9c4;
}

.coint-img {
  width: 8%;
  height: auto;
}
.coint-image img {
  width: 40px;
  height: auto;
}

.css-epd502 {
  background-color: black;
}

.calender .MuiSvgIcon-root {
  border: 1px solid;
  border-radius: 50%;
  transition: all 0.5s;
}

/* .calender .MuiSvgIcon-root:hover{
  background-color: var(--portal-theme-primary);
  color: black !important;
} */
.calender .MuiPickersCalendarHeader-label {
  font-weight: 500;
}

.coint-detail {
  align-items: center;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 9px;
}

.calender {
  justify-content: center;
  /* margin-top: -18px; */
}

.dynamite-diary .MuiDatePickerToolbar-root {
  display: none !important;
}

.cross-icon {
  padding-top: 1px;
  height: 25px;
  width: 26px;
  font-size: 13px;
  text-align: center;
  padding-top: 2px;
  border-radius: 50px;
  color: white;
  position: absolute;
  right: 1%;
  top: 12px;
  cursor: pointer;
  top: 5px;
  z-index: 9999999999999999999999;
}
.cross-icon-cross {
  padding-top: 1px;
  height: 25px;
  width: 26px;
  font-size: 13px;
  text-align: center;
  padding-top: 2px;
  border-radius: 50px;
  color: white;
  position: absolute;
  right: 3%;
  top: 21px !important;
  cursor: pointer;
  background-color: rgb(255 218 0 / 8%);
  top: 5px;
  z-index: 9999999999999;
}

.cross-icon-manage {
  height: 25px;
  width: 25px;
  font-size: 13px;
  border-radius: 50px;
  color: white;
  position: absolute;
  right: -7px;
  cursor: pointer;
  top: -7px;
  z-index: 999;
}

.icon-event-detail {
  font-size: 18px;
  right: 1%;
  top: 10px;
  justify-content: end;
}

.icon-event-detail .close-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}

.icon-event-detail .other-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}

.icon-event-detail .other-detail-icon:hover {
  background-color: var(--portal-theme-secondary);
}

.icon-event-detail .close-detail-icon svg,
.icon-event-detail .other-detail-icon svg {
  font-size: 16px !important;
}

.cross-daily {
  right: 0% !important;
}
.intentionCheckox {
  margin-top: -10px;
}

.diary-icon-remove,
.remove-poll-options {
  color: red;
  fill: red;
  margin-bottom: 2px;
  cursor: pointer;
}

.diary-icon-add,
.add-poll-options {
  color: var(--portal-theme-primary);
  cursor: pointer;
}
.styles_reactWaves__1M36F {
  padding: 4px 15px 0 10px !important;
}

.fields-container {
  margin-bottom: 70px;
}

.dynamite-diary p {
  color: #ccc9c4;
  font-size: 12px;
}

.date-heading {
  color: #ccc9c4;
}

.diary-font-weight {
  font-weight: 400 !important;
  justify-content: space-between;
}

.diary-font-weight h4 {
  font-weight: 400 !important;
}

.set-manu-position {
  position: absolute;
  top: 0px;
  right: 10px;
}

.field-container {
  width: 92%;
}

.icon-container {
  width: 8%;
  border: 1px solid gray;
  height: 53px;
  margin-top: 9px;
  margin-left: 6px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: var(--portal-theme-primary);
}

.calender .MuiDialogActions-spacing {
  display: none !important;
}

.subHeading {
  font-size: 20px;
  margin-top: 22px !important;

  margin-bottom: 20px !important;
  text-transform: capitalize;
}

.set-manu-position .MuiSvgIcon-root {
  transform: rotate3d(6, 4, -62, 91deg);
  border: transparent;
  color: white !important;
}

.mini-cards {
  position: relative;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  background: #141717;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15p;
  color: white;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}

.mini-cards h4 {
  font-weight: 400 !important;
}

.text-color {
  color: white !important;
}

.text-align {
  justify-content: flex-end;
  padding-top: 3px;
}

.gray_description {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary-color);
}

.programme-content {
  padding: 15px 15px 20px 15px;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: var(--background-primary-color);
}

.small-contained-button {
  background-color: var(--button-background-color);
  padding: 8px 16px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
  text-decoration: none;
}

.small-contained-button:disabled {
  color: #e4c07366;
  border-color: #e4c07366;
}

.view-challenge-button {
  background: #b59d4e;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 20px;
  white-space: nowrap;
  padding: 8px 20px;
  border: none;
  outline: none;
}

.lesson-detail-page .mark-contained-button {
  background-color: var(--portal-theme-primary);
  color: var(--input-text-color);
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
}

.small-outlined-button {
  background-color: var(--button-background-color);
  padding: 8px 16px;
  color: var(--background-primary-color);
  border-radius: 5px;
  border: 2px solid var(--portal-theme-primary);
}

.program-btn {
  text-align: left;
  margin-bottom: 20px;
}

.program-category-btn {
  text-align: right;
  margin-bottom: 20px;
}

.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.normal-font {
  font-size: 14px;
  font-weight: 500;
}

.feedback-programme-header {
  display: flex;
  justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.icon-style {
  color: var(--text-primary-color);
  float: right;
}

.icon-style:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
}

.lesson-card {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.payment-card {
  background-color: var(--background-secondary-color);
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.payment-card .request-type {
  font-weight: 500;
}

.data-table {
  color: var(--text-primary-color);
  text-align: center;
}

.data-table p {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.data-table .css-n1f7dt-MuiPaper-root-MuiCard-roo {
  border-radius: 0;
}

.css-qen1a1-MuiDataGrid-root {
  border: 2px solid var(--portal-theme-secondary) !important;
}

.data-table .MuiDataGrid-cell,
.css-1sstdvt-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid var(--portal-theme-secondary);
}

.data-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: var(--portal-theme-primary) !important;
}

.data-table .MuiDataGrid-menuList,
.css-oau2hf-MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: var(--text-primary-color);
}

.data-table .css-1wph1rq-MuiDataGrid-overlay {
  background-color: var(--background-secondary-color) !important;
}

.loagin-button.Mui-disabled {
  background-color: var(--portal-theme-secondary) !important;
}

.loagin-button .MuiLoadingButton-loadingIndicator {
  color: var(--portal-theme-primary) !important;
}

.loagin-button:hover {
  background-color: var(--portal-theme-primary) !important;
  color: #000;
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}

.date-color {
  color: var(--text-secondary-color) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.show-all-likes .post-creator .creator-name span {
  font-size: 12px !important;
}

.back-screen-button {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  margin-bottom: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.new-chat-button {
  background-color: var(--portal-theme-primary);
  color: #fff;
  margin-bottom: 50px;
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  border: none;
}
.new-chat-button svg {
  color: #fff;
  fill: #fff;
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}

.memories-date p {
  color: var(--text-primary-color);
  margin-top: 15px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: var(--portal-theme-secondary) !important;
}

.carousel-nav-button {
  background-color: var(--text-primary-color) !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 12px;
  /* padding-right: 5px; */
}

.css-d82q1s-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
  color: var(--text-primary-color) !important;
}

.submit-button {
  text-align: center;
  background-color: var(--button-background-color);
  border: 1px solid var(--portal-theme-primary);
  color: var(--portal-theme-primary);
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}

.upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.track .MuiSwitch-track {
  background-color: var(--portal-theme-primary) !important;
}

.event-chat-upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 10px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-chat-upload-button label img {
  width: 15px;
  height: 15px;
}

.upload-image-icon-comment {
  background-color: transparent;
  margin-top: 13px;
}

.preview,
.chat-image-preview,
.add-event-comment-text-field,
.preview-comment-image {
  position: relative;
}

.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.preview-comment-image img {
  height: 50px;
  width: 50px;
  display: inline;
  object-fit: cover;
}

.chat-image-preview img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.preview span,
.chat-image-preview span,
.preview-comment-image span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.preview-comment-image span {
  right: -10px;
}

.event-chat-send-button {
  padding: 9px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

.event-chat-emoji-picker-button {
  padding: 6px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  width: 35px;
}

.position-relative {
  position: relative;
}

.remove-google-account {
  position: absolute;
  cursor: pointer;
  top: -5px;
  right: -10px;
  height: 16px;
  width: 16px;
  background: red;
  text-align: center;
  font-size: 10px;
  border-radius: 50%;
}

.emoji-picker-button-creat-post {
  position: absolute;
  top: 0;
  right: 0;
}

.event-chat-emoji-picker-button img {
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.comment-cancel-update {
  position: absolute;
  top: 0px;
  right: -5px;
  height: 20px;
  width: 20px;
}

.comment-cancel-update svg {
  width: 15px;
}

.event-chat-send-button img {
  width: 17px;
  height: 17px;
}

.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}

.preview span:hover {
  cursor: pointer;
}

.new-memories .MuiFormControl-root {
  width: 100%;
}

.event-details {
  margin: 0px auto;
  color: #fff;
}

.event-details h1 {
  font-size: 30px;
}

.new-memories h1 {
  font-size: 20px;
  margin: 20px auto;
}

#player {
  width: 100% !important;
}

.card-full-width {
  width: 100%;
}

.rating-stars-box {
  position: absolute;
  bottom: 0%;
}

.download-icon {
  margin-top: 12px;
}

.remove-border {
  border: none !important;
  margin: 0 !important;
  z-index: 10 !important;
}

.remove-border svg {
  z-index: 1 !important;
}

.sidebar-icon svg {
  color: var(--portal-theme-primary) !important;
}

.set-display-inline .remove-border,
.affirmation-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.Ninty-Day-trackerList-dots .remove-border {
  display: inline;
  position: absolute;
  top: 60px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--portal-theme-secondary);
  text-align: center;
}

.set-image-center img {
  margin: auto;
}

/* .player-wrapper {
  position: relative;
  padding-top: 56.25%;
} */

/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
} */

.wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.accordion-summary svg {
  color: var(--portal-theme-primary);
}

.range-slider span {
  color: var(--portal-theme-primary);
}

.css-1e799a4-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}

.notes-input {
  padding: 15px;
  padding-right: 30px;
}

.slider-box {
  position: relative;
}

.join-now-btn {
  position: absolute;
  bottom: 25px;
}

.event-slider .slick-prev:before,
.event-slider .slick-next:before {
  color: var(--portal-theme-primary);
}

.event-slider .slick-prev,
.event-slider .slick-next {
  z-index: 1;
}

.programm-svg:hover {
  cursor: pointer;
}

.review-slider .slick-track {
  display: flex;
  flex-wrap: wrap;
}

.review-slider .slick-slide {
  flex: 0 0 auto;
  height: auto !important;
}

.review-slider .slick-slide > div {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--portal-theme-primary);
  height: 100%;
  border-radius: 5px;
}

.review-slider .card-border {
  border: none;
}

.dashboard-circle {
  height: 565px;
}

/* chat style Support Tickets*/
.chat-main-card {
  background-color: var(--sidebars-background-color);
  box-shadow: none;
  color: var(--text-primary-color);
}

.chat-main-card .card-title {
  color: var(--portal-theme-primary);
}

.chat-title-head {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
}

.chat-message-head {
  border-bottom: 1px solid gray;
}

.chat-title-body {
  border-right: 1px solid gray;
}

.chat-message-body {
  /* background-color: rgba(158, 163, 161, 0.08) !important; */
  /* border-top: 1px solid #282727; */
  position: relative;
  width: 100%;
}

.modal-content {
  background-color: var(--sidebars-background-color);
}

.mui-btn-close {
  /* color: var(--portal-theme-primary) !important; */
  text-align: right;
}

.mui-btn-close-icon {
  position: absolute;
  right: 3px;
  top: 3px;
}

.chat-main-card hr {
  margin: 0;
}

.chat-side-bar {
  padding-right: 0 !important;
}

.chat-title-body:hover {
  background-color: rgba(158, 163, 161, 0.08) !important;
  cursor: pointer;
}

.uiDataGrid-sortIcon {
  display: none;
}

.open-ticket-color {
  background-color: #007bff;
  color: var(--input-text-color);
  border-color: #007bff;
  width: 90px;
}

.solved-ticket-color {
  background-color: #28a745;
  color: var(--input-text-color);
  border-color: #28a745;
  width: 90px;
}

.waiting-ticket-color {
  background-color: #ffc107;
  color: #000;
  border-color: #ffc107;
  width: 90px;
}

.css-qen1a1-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.dx-sort {
  display: none !important;
}

.suppor-ticket-data-table .MuiDataGrid-row {
  cursor: pointer;
}

.suppor-ticket-data-table .MuiIconButton-sizeSmall {
  display: none;
}

.suppor-ticket-data-table .MuiDataGrid-cell:focus,
.suppor-ticket-data-table .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.suppor-ticket-data-table .MuiDataGrid-columnHeaders {
  border: 2px solid var(--portal-theme-secondary) !important;
  color: var(--portal-theme-primary) !important;
}

.suppor-ticket-data-table {
  border: 2px solid var(--portal-theme-secondary) !important;
}

.suppor-ticket-data-table .MuiDataGrid-iconSeparator {
  cursor: pointer;
}

.suppor-ticket-data-table .MuiDataGrid-virtualScrollerContent {
  min-height: 200px !important;
}

.custom-popover-box .remove-border {
  right: 5px;
  width: 30px;
  position: absolute;
  top: 40px;
}

.ticket_image_preview img:hover {
  cursor: pointer;
}

.SnackbarItem-action {
  display: inline;
  max-width: 80px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
}

/* for datepicker background on mobile */
.MuiDialog-paperScrollPaper {
  background-color: var(--popup-background-color);
  color: var(--input-text-color);
}

.bottom-fixed-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
}
.member-box p {
  padding-top: 4px;
  font-size: 13px;
}
.medi-avatar {
  padding-top: 25px;
}

/* end chat style Support Tickets*/

.associate-section {
  padding-left: 40px;
  color: var(--text-primary-color);
}

@media only screen and (max-width: 375px) {
  /* .custom-video-player {
    height: 100% !important;
  } */
  .lesson-heading {
    padding-top: 10px;
  }

  .side-image-mui-card-heading {
    padding-top: 10px;
  }

  .card-spacing {
    margin-top: 0.75rem;
  }

  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .preview img {
    height: auto;
  }

  .fc-button-primary {
    padding: 3px !important;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }

  .fc-toolbar-title {
    font-size: 16px !important;
  }
}

/*=================================  full calender  =======================*/

.calendar-events-box {
  margin-top: 10px;
}

.calendar-events-text {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}

.fc .fc-popover-title {
  margin: 0 auto !important;
}
.fc-daygrid-day-frame {
  overflow: hidden;
  min-height: 60px !important;
}

.fc-daygrid-day .start_time_for_popup {
  display: none;
}

.fc-daygrid-day-bottom {
  z-index: -1 !important;
}

.fc-daygrid-day-events {
  padding-top: 1rem;
  color: white;
  display: contents;
  position: absolute !important;
  top: 10px;
}

.calendar-event-title {
  color: #fff;
  margin-bottom: 5px;
  font-size: 13px;
}
.calendar-event-title:hover {
  color: var(--portal-theme-primary);
}
.calendar-events-text {
  color: white;
}

.full-calendar .fc-more-popover {
  background-color: var(--popup-background-color);
  z-index: 1;
}

.full-calendar .fc-more-popover .fc-popover-header {
  background: var(--fc-neutral-bg-color, hsla(0, 0%, 81.6%, 0.3));
  color: #fff;
}

.fc-toolbar-chunk {
  display: inline-flex;
}

.fc-day-grid-event > .fc-content {
  white-space: normal;
}

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: var(--portal-theme-primary);
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
}

.fc-today-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-prev-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-right {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
}

.fc-next-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-dayGridMonth-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridDay-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-col-header-cell-cushion {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: var(--text-primary-color);
}

.fc-daygrid-day-top {
  float: left !important;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.full-calendar-new-flow .fc-header-toolbar {
  display: none;
}

.toollbar-group-buttons .small-contained-button {
  border-radius: 0;
  color: #fff;
  outline: none !important;
  background-color: transparent !important;
  padding: 0.4em 0.65em;
}

.toollbar-group-buttons .small-contained-button svg {
  color: #fff !important;
  font-size: 16px;
}

.toollbar-group-buttons .small-contained-button:first-child {
  border-radius: 5px 0 0 5px;
}
.toollbar-group-buttons .small-contained-button:last-child {
  border-radius: 0 5px 5px 0;
}
.toollbar-group-buttons .selected-button {
  color: var(--portal-theme-primary);
}

.full-calendar-toolbar .today-text {
  opacity: 0.65;
  margin-right: 16px;
}

.fc-timegrid-event {
  background: none !important;
  border: none !important;
}

.fc .fc-highlight {
  background-color: transparent !important;
}

.calendar-card {
  background-color: var(--sidebars-background-color);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.calendar-week-card {
  background-color: var(--sidebars-background-color);
  border: 7px solid #000;
  border-radius: 13px;
}

.calendar-week-card h4 {
  font-size: 14px;
  margin-bottom: 2px;
}

.calendar-card .card-event-day {
  padding: 5px;
  color: var(--portal-theme-primary);
  text-align: center;
}

.calendar-card .calendar-dot {
  height: 13px;
  width: 13px;
  margin: 0 6px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.calendar-card .card-event-title {
  padding: 5px;
}

.calendar-card .card-event-title:hover {
  color: var(--portal-theme-primary);
}

.calendar-card .card-event-title span {
  color: var(--text-secondary-color);
  font-size: 13px;
}

.calendar-card .week-event-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.log-detail {
  width: 120px;
}

.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: flex;
}

.dl-button {
  border: 1px solid #ccc5c5;
  padding: 5px;
  display: flex;
}
.pointer {
  cursor: pointer !important;
}

.dl-button2 {
  border: 1px solid var(--background-primary-color);
  padding: 5px;
  display: flex;
}

/* pinterest layout design */

.pinterest-card span {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
  padding: 0px 15px;
}

#columns {
  column-width: 280px;
  column-gap: 15px;
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
  margin-top: 0;
}

div#columns div {
  background-color: var(--background-secondary-color);
  border-radius: 16px;
  margin-bottom: 15px;
}

div.show-pointer div:hover {
  cursor: pointer;
}

.pinterest-card {
  position: relative;
}

div#columns figure {
  margin: 0 2px 15px;
  transition: opacity 0.4s ease-in-out;
  border-radius: 16px;
  display: inline-block;
  width: 99%;
  padding-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}

div#columns figure img {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

div#columns figure p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 15px;
}

div#columns small {
  font-size: 1rem;
  float: right;
  text-transform: uppercase;
  color: #aaa;
}

div#columns small a {
  color: #666;
  text-decoration: none;
  transition: 0.4s color;
}

/* div#columns:hover figure:not(:hover) {
  opacity: 0.4;
} */

@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0px;
  }

  #columns figure {
    width: 100%;
  }
  .calendar-event-title {
    display: none;
  }
  .event-title-dot {
    margin-top: 0;
  }
}

/* pinterest end */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}

.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}

.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}

.quotes-heading {
  line-height: 1.235;
  margin-bottom: 25px;
}

.container h2,
.event-title h6 {
  color: var(--portal-theme-primary);
}

.dashboard-heading {
  color: var(--portal-theme-primary);
  margin: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  margin-bottom: 40px;
}

.add-button {
  text-align: right;
  padding-top: 0.25rem;
}

.anchor-style {
  text-decoration: none;
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.anchor-style:hover {
  text-decoration: underline !important;
}
.drawer-section {
  max-width: 400px;
}

.MuiDrawer-paperAnchorRight {
  width: 400px !important;
  max-width: 100% !important;
}

.drawer-padding {
  padding: 0 !important;
}

.drawer-cross-btn {
  margin-top: 12px;
  padding: 6px;
  margin-bottom: 0px !important;
}

.drawer-cross-btn svg {
  font-size: 16px;
}

.custom-video-player iframe {
  height: 100% !important;
}

.react-audio-player {
  width: 100%;
}

button.disabled:hover,
.shopping-cart-checkout input:disabled:hover {
  cursor: not-allowed;
}

.shopping-cart-checkout h2 {
  font-size: 20px;
}

.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.no-access-string p {
  margin-bottom: 0 !important;
  color: #fff;
}

.download-icon {
  text-align: end;
  margin-bottom: 20px;
}

.download-icon .icon-style {
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 40px;
  right: 10px;
}

.download-icon span {
  height: 20px !important;
  width: 20px !important;
}

.section-button button {
  /* display: block; */
  background-color: #145b6c;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
}

.dot-btn {
  position: absolute;
  right: 0;
  top: 5px;
}

.set-max-height {
  max-height: 200px !important;
}

.goal-statement-messages .tab {
  margin-top: 10px;
}

.responce-messages .set-image-width {
  width: 23% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

/* .event-title {
  background-color: var(--sidebars-background-color);
} */
.SnackbarItem-message {
  padding-right: 40px !important;
}

.SnackbarItem-action svg,
.SnackbarItem-message svg {
  color: var(--input-text-color) !important;
}

swd-pin-field {
  display: flex;
  justify-content: space-between;
}

.responce-messages .set-title-width {
  width: 57% !important;
}

.forms-drawer .simplebar-wrapper {
  height: 100% !important;
  padding-bottom: 20px;
}

.MuiDrawer-modal.forms-drawer {
  z-index: 1300 !important;
}

.circle-image .set-title-width span {
  color: var(--text-primary-color);
}

.circle-image .MuiAvatar-root {
  width: 60px;
  height: 60px;
}
.circle-image-chat .MuiAvatar-root {
  width: 40px !important;
  height: 40px !important;
}

.css-15ez4hi-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-radius: 10px !important;
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.password-tooltip {
  border: 2px solid var(--portal-theme-primary) !important;
  color: var(--portal-theme-primary) !important;
}

.carousel-container a {
  text-decoration: none;
}

.simplebar-content .css-1gtfl7l {
  width: 100% !important;
  z-index: 1111111111;
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  box-shadow: none;
}

.zoom-password svg {
  font-size: 16px;
  margin-left: 5px;
}

.snackbar-cross-icon svg {
  color: #fff;
  font-size: 16px;
}

.recordings-card {
  background-color: var(--background-secondary-color);
  margin-bottom: 20px;
  padding: 20px;
}

audio::-webkit-media-controls-panel {
  width: 100% !important;
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}

.dashboard_description img {
  margin: 0px auto !important;
}

.dashboard_description p {
  margin-bottom: 5px !important;
  font-size: 16px;
}

.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--portal-theme-primary);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

.sidebar-drawer {
  background-color: var(--background-primary-color);
}

.date-picker button {
  background-color: red;
}

.goal_achieved_lower_text {
  display: none;
}

@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 576px) {
  .add-button {
    text-align: center;
    padding-top: 0;
    margin-bottom: 30px;
  }

  .MuiDrawer-paperAnchorRight {
    width: 100% !important;
  }

  .lesson-card-image {
    width: 100%;
  }

  .side-image-mui-card-image {
    width: 100%;
  }

  .kims-reply-card .set-image-width {
    width: 23% !important;
  }

  .kims-reply-card .set-title-width {
    width: 57% !important;
  }

  .program-btn,
  .program-category-btn {
    text-align: center;
  }

  .goal_achieved_lower_text {
    display: block;
  }

  .goal_achieved_upper_text {
    display: none;
  }

  .dashboard-circle {
    height: 325px;
  }

  .css-qen1a1-MuiDataGrid-root {
    border-left: none !important;
    border-right: none !important;
    padding: 0 10px;
  }

  header img {
    width: 100% !important;
  }

  .SnackbarItem-message {
    max-width: 100%;
  }

  .support-ticket-button-contained,
  .support-ticket-button-outlined {
    margin-top: 20px;
  }
}

.fc-more-link,
.fc-button-active {
  color: var(--portal-theme-primary) !important;
}

@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }

  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }

  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
}

/* Comunity */

.new-community-page .profile-cards {
  cursor: auto;
}

.new-community-page .banner-section img {
  border-radius: 7px;
}

.banner-bg-image {
  width: 100%;
}

.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
  /* max-width: 100px; */
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
  background-color: black;
}

.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}

/* membership-section  */
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}

.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}

.favourite-box {
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 10px;
  height: 40px;
  padding: 5px;
  text-align: center;
  width: 40px;
}

.coins-icon {
  width: 16px;
}

.score-row {
  font-size: 12px;
}

.score-row img {
  width: 16px;
  display: inline;
  margin-left: 5px;
}

.favourite-box img {
  display: inline;
}

.favourite-box p {
  margin-bottom: 0;
}

.new-community-page .membership-section button,
.membership-section .reward-button button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  min-width: 70%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  padding: 3px 5px;
}

.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}

.board-main-image-box,
.board-inner-image-box,
.leader-board-component {
  position: relative;
}

.leader-board-component .leader-board-info-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 13px;
  cursor: pointer;
}

.board-main-image-box .board-image-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 25px;
}

.board-inner-image-box .board-image-icon {
  position: absolute;
  left: 5%;
  bottom: -10px;
}

.board-inner-image-box .rounded-image {
  height: 65px;
  width: 65px;
  object-fit: cover;
  border-radius: 5px;
  margin: auto;
  text-transform: uppercase;
}

.search-input input {
  background-color: #262626 !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 20px;
  border: none !important;
  height: 35px;
  padding-left: 15px;
}

.create-post-title-form .search-input input {
  background-color: #262626a1 !important;
  border-radius: 2px;
  margin-bottom: 0 !important;
}

.textAreaAutoSize {
  width: 100%;
  border-radius: 8px;
  background: #141717;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  color: white;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}

.search-input textarea {
  background-color: #262626 !important;
  font-size: 13px;
  border-radius: 5px;
  border: none !important;
  padding-left: 15px;
}

/* .search-input input:focus {
  border: none;
  background-color: #262626;
} */

.favourite-buttton-box button,
.favourite-buttton button {
  background-color: #fff !important;
  border: none !important;
  margin-bottom: 10px;
  font-size: 13px;
  color: #000 !important;
}

.favourite-buttton-box svg,
.favourite-buttton svg {
  color: var(--portal-theme-primary);
  font-size: 20px;
  margin-top: -3px;
}

.favourite-videos-buttton-box button {
  background-color: #000;
  color: #fff;
  border: 1px solid var(--portal-theme-primary) !important;
  font-weight: 500;
  white-space: nowrap;
}

.favourite-videos-buttton-box svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}

.open-create-post-card .profile-cards {
  min-height: auto;
}

.input-with-profile img {
  border-radius: 50%;
  margin-right: 2%;
}

.input-with-profile .input-field {
  background-color: #262626;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
  font-size: 14px;
}

.search-input ::placeholder {
  color: #fff;
}

.likes-post {
  justify-content: start;
  font-size: 13px;
  align-items: center;
}

.likes-post:hover {
  cursor: pointer;
}

.likes-post .MuiAvatarGroup-avatar {
  width: 12px;
  height: 12px;
  font-size: 11px;
}

.likes-post img {
  display: inline;
  height: 15px;
  width: 16px;
}

.likes-post svg {
  font-size: 20px;
}

.likes-post span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.comment-icons {
  padding: 0px;
}

.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
}

.comment-icons .MuiChip-root {
  border: none;
}

.comment-icons .MuiChip-root:hover {
  background-color: #262626;
}

.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  fill: #fff;
}

.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #fff !important;
  font-size: 12px !important;
}

.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}

.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.view-comment #load-more-comments {
  color: #f0c26a;
  text-decoration: underline;
}

.new-community-page .img-back-border img,
.reward-button .img-back-border img {
  display: inline;
}

.new-community-page .img-back-border,
.reward-button .img-back-border img {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
  position: relative;
}

/* success-wrapper  */
.new-community-page .success-wrapper,
.mission-control-page .success-wrapper {
  padding-top: 40px;
}

.new-community-page .success-wrapper h1,
.mission-control-page .success-wrapper h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.new-community-page .success-wrapper h2,
.mission-control-page .success-wrapper h2 {
  font-size: 15px;
  font-weight: 300;
  margin: 13px 0px;
  color: #fff;
}

.new-community-page .success-wrapper span,
.mission-control-page .success-wrapper span {
  color: #93b36c;
}

.new-community-page .success-wrapper p,
.mission-control-page .success-wrapper p {
  line-height: 2;
}

.new-community-page .btn-wrapper button,
.mission-control-page .btn-wrapper button {
  border: none;
  padding: 4px 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.new-community-page .btn-wrapper .play-btn,
.mission-control-page .btn-wrapper .play-btn {
  background: #fff;
  color: #000 !important;
}

.new-community-page .btn-wrapper .more-info-btn {
  background: #555454;
  color: #fff;
}

.source-session.show-less-more-box h2 {
  font-size: 20px;
  color: #fff;
}

.new-community-page .slider-lower-buttons button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 3px;
  width: 130px;
  text-align: left;
  padding-left: 10px;
}

.new-community-page .activity-profile img {
  width: 30px;
  margin-top: 5px;
}

.new-community-page .wealth-wallet {
  position: relative;
}

.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}

.score-right-side h2 {
  margin-bottom: 0.8rem;
  color: #d74336;
}

.score-right-side h5 {
  margin-bottom: 0.8rem;
}

.main-section {
  position: relative;
}

.main-section hr {
  margin: 7px auto;
}

.live-embed-code iframe {
  max-width: 100% !important;
}

.leader-board-popup {
  position: relative;
}

.new-community-page .info-popup-box {
  position: absolute;
  right: 0px;
  top: 33px;
  padding: 5px;
  background-color: #000;
  border-radius: 10px;
  z-index: 2;
  max-width: 350px;
}

.new-community-page .info-popup-box .info-card {
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;
  position: relative;
}

.mission-control-page .info-popup-box .info-card {
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  position: absolute;
  background-color: #000;
  z-index: 1;
  right: 40px;
  top: 50px;
}

.mission-control-page .info-popup-box .info-card h2 {
  color: #fff;
  font-size: 20px;
}

.new-community-page .cross-sigh,
.mission-control-page .info-popup-box .cross-sigh {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.new-community-page .cross-sigh span,
.mission-control-page .info-popup-box .cross-sigh span {
  padding: 0px 1px 4px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.mission-control-page .info-popup-box .cross-sigh svg {
  fill: #fff;
  color: #fff;
  padding: 2px;
}

.new-community-page .cross-sigh {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.new-community-page .cross-sigh span {
  padding: 0px 1px 4px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.new-community-page .cross-sigh span svg {
  fill: #fff;
  color: #fff;
  padding: 2px;
}

.new-community-page .info-popup-box .info-card h2 {
  color: #fff;
  font-size: 20px;
}

.new-community-page .info-popup-box .info-card p {
  /* color: #fff; */
  font-size: 12px;
}

.new-community-page .join-today-btn {
  color: #000;
  background-color: var(--portal-theme-primary);
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 80px;
  outline: none;
  border: none;
}

.mission-control-page .wealth-wallet {
  position: relative;
}

.new-community-page .wealth-wallet span,
.mission-control-page .wealth-wallet span,
.mission-control-page .wealth-wallet h5,
.new-community-page .wealth-wallet h5 {
  color: var(--portal-theme-primary) !important;
  font-size: 11px;
}

/* .wealth-wallet h2 {
  font-size: 20px;
} */

.new-community-page .MuiSlider-colorPrimary {
  margin-bottom: 5px;
}

.new-community-page .community-current-pods p {
  font-size: 8px;
}

.new-community-page .community-current-pods svg {
  font-size: 20px;
}

.new-community-page .live-svg svg {
  color: #93b36c;
}

.new-community-page .starting-svg svg {
  color: #ffc107;
}

.new-community-page .total-points .icon-image img {
  width: 30px;
  margin-top: 7px;
}

.new-community-page .upcoming-events p,
.new-community-page .current-events p {
  font-size: 13px;
  color: var(--text-secondary-color);
}

.new-community-page .community-star-icon img {
  width: 40px;
  margin: 0 auto;
}

.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  height: 16px;
  margin: 0;
}

.community-slider,
.increase-scale {
  position: relative;
}

.community-slider .event-title {
  font-size: 16px;
}

.increase-scale svg {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 30px;
  color: #000;
  cursor: pointer;
  z-index: 11111 !important;
}

.increase-scale .star-icon svg {
  color: var(--portal-theme-primary);
}

.increase-scale .favourite-loading svg {
  font-size: 125px;
  left: 20px;
}

.community-slider .increase-scale img {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.community-slider .increase-scale img:hover {
  transform: scale(1.3);
}

/* .community-slider .increase-scale {
  z-index: 11;
  transform: scale(5);
  top: 20px;
} */

.hide-on-desktop {
  display: none;
}

.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  font-size: 17px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: #262626a1;
  color: red;
}

.create-post-title-box h2 {
  color: #fff;
}

.create-post-title-form .upload-button label {
  width: 100%;
  height: auto;
  background-color: #262626a1;
  padding: 0 !important;
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: #1d1c1d;
  font-size: 16px;
  border-radius: 50%;
  color: red;
}

/* .create-post-title-form .preview img {
  width: 100%;
  height: auto;
  max-height: 150px;
} */

.post-creator {
  display: flex;
}

.post-creator .creator-name h3 {
  font-size: 13px;
}

.post-creator img {
  height: 34px;
  width: 34px;
  border-radius: 50%;
}

.live-streaming-enabled .post-creator img {
  border: 2px solid red;
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}

.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: #262626a1;
  height: 40px;
  width: 40px;
  border-radius: 2px;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: #fff;
  font-size: 20px;
  fill: #fff;
}

.video-image-selection .selected svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-secondary);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: red;
  cursor: pointer;
}

.post-submit-btn {
  /* background-color: #fff; */
  /* color: #000; */
  /* border: none !important; */
  padding: 5px 30px;
}

.show-all-likes-tabs img {
  width: 16px;
}

.show-all-likes-box {
  overflow: hidden;
  height: 480px;
}

.show-all-likes .post-creator .creator-name h3 {
  color: #fff;
  text-align: start;
  margin-bottom: 0 !important;
}

.cross-show-all-likes-model {
  position: absolute;
  right: 15px;
  top: 10px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
  text-align: center;
  cursor: pointer;
}

.like-profile-image {
  position: relative;
}

.like-profile-image .tiny-like-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.like-profile-image .tiny-like-icon img {
  width: 13px;
  height: auto;
}

.favourite-videos-list,
.feed-image,
.post-description {
  cursor: pointer;
}

.feed-image img {
  width: 100%;
  object-fit: cover;
}

.post-description {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.post-description p {
  font-weight: 300;
  display: inline;
  font-size: 14px;
}

.post-description a {
  color: var(--portal-theme-primary);
  text-decoration: none;
  z-index: 1;
}

.post-description span {
  z-index: 111;
  color: var(--portal-theme-primary);
}

.favourite-videos-list h3 {
  color: var(--portal-theme-primary);
}

.liking-post img {
  width: 20px !important;
  font-size: 20px;
}

.poster-logo img {
  width: 45px;
}

.profile-comments .affirmation-dots .remove-border {
  top: 5px;
  width: 28px;
  height: 28px;
}

.profile-comments .affirmation-dots .remove-border svg {
  font-size: 16px !important;
}

.profile-comments.inception {
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.post-detail-page .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px !important;
}

.post-detail-page .main-section {
  max-height: 80vh;
  overflow: auto;
}

.post-detail-box {
  position: relative;
}

.post-detail-box .cross-show-all-likes-model {
  position: absolute;
  left: 20px;
  top: 20px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
  background-color: #000000c2;
}

.feed-detail-popup {
  background-color: #000;
  height: 100vh !important;
  overflow-y: scroll;
}

.feed-detail-popup .profile-cards {
  height: 100vh;
  border-radius: 0;
  box-shadow: none;
  overflow: auto;
}

.feed-detail-popup .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.feed-detail-popup .comment-icons .MuiChip-label img {
  display: inline;
  width: 16px;
}

.gained-cores h2 {
  color: var(--portal-theme-primary) !important;
}

.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}

@media (max-width: 1024px) {
  .new-community-page .membership-section a {
    padding: 7px 5px;
  }

  .new-community-page .membership-section button {
    font-size: 9px;
  }
}

@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }

  .new-community-page .img-border {
    height: 88px;
  }

  .new-community-page .join-today-btn {
    margin-top: 100px;
  }

  .input-with-profile .input-field {
    line-height: 47px;
  }

  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }

  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }

  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }

  .score-row img {
    width: 20px;
  }

  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }

  .show-on-desktop {
    display: none;
  }

  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }

  .community-card-title h4 {
    font-size: 20px;
  }

  .new-community-page .activity-profile img {
    margin-top: 15px;
  }

  .community-card-title h6 {
    font-size: 18px;
  }

  .score img {
    display: inline;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .new-community-page .community-current-pods p {
    font-size: 16px;
  }

  .community-card-title h5 {
    font-size: 20px;
  }

  .new-community-page .upcoming-events p {
    font-size: 17px;
  }

  .total-points p {
    font-size: 30px;
  }

  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }

  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }

  .community-card-title .card-title {
    font-size: 20px;
  }

  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }

  .poster-name .card-title {
    font-size: 16px;
  }

  .poster-name .date-color {
    font-size: 14px !important;
  }

  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }

  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }

  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }

  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }

  .wider-card .mui-card-button,
  .mui-card-button {
    left: 27%;
  }
}

@media (max-width: 540px) {
  .new-community-page .success-wrapper {
    padding-top: 33px;
  }

  .new-community-page .slider-lower-buttons {
    text-align: center;
  }

  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }

  .score-right-side h2 {
    margin-top: 0 !important;
  }

  .new-community-page .comment-icons {
    padding: 0 5px;
  }

  .likes-post {
    font-size: 11px;
  }

  .post-detail-page .main-section {
    max-height: 100vh;
    overflow: auto;
  }

  .community-main-section {
    overflow: auto;
  }
}

@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }

  .btn-wrapper button {
    font-size: 13px;
  }

  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }

  .score-row img {
    width: 40px;
  }

  .community-card-title h4 {
    font-size: 13px;
  }

  .community-card-title h6 {
    font-size: 14px;
  }

  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }

  .community-card-title h5 {
    font-size: 16px;
  }

  .new-community-page .community-current-pods p {
    font-size: 12px;
  }

  .new-community-page .upcoming-events p {
    font-size: 12px;
  }

  .favourite-buttton-box {
    margin-top: 15px;
  }

  .community-card-title .card-title {
    font-size: 14px;
  }

  .community-card-title .card-title.date-time {
    font-size: 12px !important;
  }

  .reduce-margin-from-para {
    font-size: 13px;
  }

  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
}

/* community css */
.hz-scroll {
  /* background-color: #1d1c1d; */
  background: linear-gradient(#1d1c1d 68%, black 32%);
  overflow: auto;
  white-space: nowrap;
  border-radius: 10px;
}

.hz-scroll .hz-scroll-div {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  width: 200px;
}

.hz-scroll .hz-scroll-left {
  position: absolute;
  top: 70px;
  left: -5px;
}

.hz-scroll .hz-scroll-right {
  position: absolute;
  top: 70px;
  right: -5px;
}

.hz-scroll .hz-scroll-icon {
  color: #000;
  background-color: #b88d38;
  border-radius: 50%;
  font-weight: 800;
  padding: 2px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
}

.hz-scroll .hz-scroll-icon:hover {
  background-color: var(--portal-theme-primary);
}

.hz-scroll .gb-left {
  border-left: solid 2px #e4b047;
}

.hz-scroll::-webkit-scrollbar {
  display: none;
}

.img-border {
  margin-top: -92px;
  margin-left: -10px;
}

.img-border img {
  height: 110px;
}

.hz-scroll-title-odd {
  position: relative;
  top: -5px;
  z-index: 30;
}

.hz-scroll-title-even {
  position: relative;
  top: -5px;
  z-index: 30;
  right: 100px;
}

/* customize MUI slider label */
.coin-slider .MuiSlider-valueLabel {
  background-color: #fff;
}

.coin-slider-label {
  color: black !important;
}

.count-number-text {
  color: white !important;
}

.load-more-text {
  cursor: pointer;
}

.coin-icon {
  width: 22px;
}

.whole-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -40px -10px;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.notification-popover-loader {
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.live-chat-loader-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 100%;
}

.live-chat-loader {
  top: 40%;
  left: 45%;
}

.score-row .activity-title {
  font-size: 14px !important;
  font-weight: 500;
}

.activity-win {
  font-size: 14px !important;
  font-weight: 500;
}

.comment-icons img {
  width: 16px;
  height: 16px;
}

.force-disabled {
  pointer-events: none;
  opacity: 0.4;
  display: none;
}

.live-comments {
  position: relative;
  background-color: transparent;
  border: 1px solid #1d1c1d;
}

.bottom-add-comment {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--background-secondary-color);
  z-index: 10;
}

/* custom scrollbar start style */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* custom scrollbar end style*/

.live-comments-list {
  height: 400px;
  overflow-y: auto;
}

.pinned-comments-list {
  max-height: 130px;
  overflow-y: auto;
}

.new-chat-chip {
  position: absolute;
  bottom: 150px;
  font-size: 10px;
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
  padding: 5px 10px;
  border-radius: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}

.scroll-top-community {
  position: fixed;
  bottom: -80px;
  font-size: 14px;
  background-color: var(--portal-theme-primary);
  color: #000;
  border-radius: 2px;
  right: 30px;
  cursor: pointer;
  z-index: 9999;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 1s ease;
}

.click-able-link {
  text-decoration: none;
  color: var(--portal-theme-primary);
}

.pinned-comment {
  border-bottom: solid 1px var(--portal-theme-primary);
  /* border-color: var(--portal-theme-primary); */
  position: absolute !important;
  width: 100%;
  z-index: 999;
  max-height: 150px;
  overflow: auto;
  background-color: var(--sidebars-background-color);
  /* border-bottom: 1px solid var(--portal-theme-primary); */
}

.pinned-comment .profile-comments {
  border-bottom: 4px solid #000;
  margin-bottom: 0 !important;
}

.pinned-icon {
  color: var(--portal-theme-primary);
}

.inception .date-color {
  font-size: 9px !important;
  font-weight: 400 !important;
}

.inception .affirmation-dots .remove-border {
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
}

.inception .affirmation-dots .remove-border svg {
  font-size: 14px !important;
}

.inception .post-description {
  margin-bottom: 4px !important;
}

.inception .post-description span p {
  font-size: 14px !important;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.inception p {
  font-size: 14px !important;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.inception .post-description span {
  font-size: 13px !important;
  font-weight: 400;
  color: var(--portal-theme-primary);
  z-index: 0;
}

.chat-comment-border hr {
  margin: 1px auto;
}

.like-reply-inception-box {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
}

.like-reply-inception-box .action-box {
  /* border: 1px solid var(--portal-theme-secondary); */
  padding: 2px 5px;
  /* padding: 2px 15px; */
  text-align: center;
  /* border-radius: 15px; */
  /* color: var(--text-secondary-color); */
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.like-reply-inception-box img {
  display: inline-block;
  width: 14px;
}

.like-reply-inception-box svg {
  font-size: 14px;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.show-btn-disabled {
  color: #e4c07366;
  border-color: #e4c07366;
}

.goal_statement_yes_icons img {
  height: 25px;
}

.single-comment-card {
  background-color: #282728;
  padding: 10px;
  border-radius: 3px;
}

.single-comment-card .post-description {
  margin-bottom: 0;
}

/* .single-comment-card-dots .remove-border {
  top: 0 !important;
} */

.single-comment-card .card-title {
  font-size: 14px;
}

.like-comment span {
  color: var(--text-secondary-color);
  margin-right: 10px;
  margin-top: 5px;
}

.like-comment span:hover {
  text-decoration: underline;
}

.liked-comment {
  color: var(--portal-theme-primary) !important;
}

.like-reply-box span {
  color: #dcdada;
}

/* .comment-like-heart {
  width: 100%;
} */
.comment-like-heart .comment-like-heart-count {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  /* padding: 0px 8px; */
  font-size: 13px;
  margin-left: -5px;
  cursor: pointer;
}

.comment-like-heart .comment-like-heart-image {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 3px;
}

.support-ticket-comment-message a {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.pods-description p {
  margin-bottom: 0;
}

.live-event-comment-message {
  margin-top: 5px;
}

.live-event-comment-message img {
  width: 12px;
  /* width: 14px; */
  height: 11px;
  /* height: 12px; */
  margin-top: 4px;
  /* margin-right: 5px; */
}

.live-event-comment-message .comment-like-heart-image,
.live-event-comment-message .comment-like-heart-count {
  max-height: 20px;
}

.event-comment-like-box .comment-like-heart {
  position: absolute;
  bottom: -10px;
  right: 0;
}

.notification-icons-btn {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}
.unread_chat_counts {
  color: var(--portal-theme-primary);
  text-align: left;
}

.notification-popover-paper {
  top: 5px !important;
}

.notification-popover {
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.participants-popover {
  min-height: 40vh;
}

.notification-title {
  color: var(--portal-theme-primary);
}

.notification-item,
.notification-item:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
}

.notification-item-unread,
.notification-item-unread:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
}

.notification-item-unread span {
  color: #fff;
}

.load-more-notification-btn button {
  margin: 10px auto;
  padding: 4px 8px;
}

.tiny-like-icon-notification img {
  width: 16px !important;
}

.board-main-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.goal_statement_sidebar {
  height: 15px;
  margin-left: 40px;
  color: #31a24c;
  fill: #31a24c;
}

.chat-image-preview img {
  height: 45px;
  width: 45px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.comment-view-attachment {
  color: var(--portal-theme-primary);
  font-size: 11px;
  text-decoration: none;
  margin-left: 10px;
}

.support-ticket-comment-message a {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.support-ticket-comment-message a {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.support-ticket-comment-message a {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.support-ticket-comment-message a {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.is-sticky {
  position: fixed;
  top: 64px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

/* .community-left-sidebar {
  height: calc(100vh - 65px);
  overflow: auto;
} */
.goal-statement-completed-text p {
  color: var(--portal-theme-primary);
  font-style: italic;
  text-align: end;
}

.lesson_detailed_description a {
  color: var(--portal-theme-primary);
}

.vault-filter-button {
  background-color: rgba(145, 158, 171, 0.08);
  padding: 6px 20px;
}

.download-pdf-button {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 5px;
  background-color: var(--button-background-color);
  font-style: normal;
}

.download-pdf-button-box {
  text-align: end;
}

@media (max-width: 540px) {
  .goal-statement-completed-text {
    text-align: center;
  }

  .goal-statement-completed-text p {
    text-align: center;
  }

  .download-pdf-button-box {
    text-align: center;
  }
}

.add-event-comment .comment-submit-button {
  padding: 4px 6px;
}

.support-ticket-comment-message a {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.menus-list {
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--input-text-color);
  width: 100%;
}

.navbar-icon {
  color: var(--portal-theme-primary);
  margin-right: 16px;
}

.dd-shop-cards .mui-custom-card-description {
  font-size: 24px;
}

.shop-detail-price {
  font-size: 30px;
}

.add-to-cart-button svg {
  margin-right: 10px;
  font-size: 20px;
}

.shop-modal-box .item-title {
  display: flex;
  justify-content: space-between;
}

.shop-modal-box .item-title span {
  width: 30px;
  height: 30px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  text-align: center;
  padding-top: 20px;
  font-size: 19px;
  padding-top: 0px;
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.shop-modal-box .item-image img {
  width: 60px;
  border-radius: 10px;
}

.shop-modal-box .single-cross-button span {
  background-color: var(--portal-theme-secondary);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  color: var(--portal-theme-primary);
}

.set-input-padding {
  padding: 16.5px 14px;
}

.shop-modal-box .subtotal span {
  font-size: 20px;
  margin: 0 5px;
}

.shop-modal-box .item-quantity,
.detail-item-quantity .item-quantity {
  display: flex;
  border: 1px solid var(--portal-theme-secondary);
  justify-content: space-between;
  padding: 0 0px;
  font-size: 20px;
  max-width: 115px;
}

.detail-item-quantity .item-quantity {
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
}

.shop-modal-box .item-quantity input,
.detail-item-quantity .item-quantity input {
  width: 60px;
  background-color: transparent;
  outline: none;
  border-left: 1px solid var(--portal-theme-secondary);
  border-right: 1px solid var(--portal-theme-secondary);
  border-bottom: 0;
  color: var(--input-text-color);
  font-size: 16px;
  text-align: center;
}

.detail-item-quantity .item-quantity input {
  border-left: 1px solid var(--portal-theme-primary);
  border-right: 1px solid var(--portal-theme-primary);
}

.shop-modal-box .item-quantity span,
.detail-item-quantity .item-quantity span {
  cursor: pointer;
}

.complete-Status {
  color: #28a745;
}

.shop-item-checkout .item-image {
  position: relative;
}

.shop-item-checkout .items-quantity {
  background-color: var(--portal-theme-primary);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  color: #fff;
  position: absolute;
  top: -5px;
  right: 0;
}

.shop-item-checkout-bill {
  background-color: var(--sidebars-background-color);
  min-height: 90vh;
  padding: 50px;
}

.image-slider .image-thumbnails {
  display: flex;
  justify-content: center;
}

.image-slider .main-image img {
  /* max-height: 400px; */
}
.disable-pointer {
  cursor: not-allowed !important;
}
.image-slider .image-thumbnails img {
  width: 60px;
  height: 50px;
  cursor: pointer;
}

.shop-cards .mui-custom-card-content h3 {
  font-size: 1.15rem;
  color: #fff;
  font-weight: 600;
}

.shop-cards .mui-custom-card-content .mui-custom-card-description {
  font-size: 16px;
  color: var(--portal-theme-primary);
}

.image-slider .image-thumbnails .is_selected {
  border: 1px solid var(--portal-theme-primary);
  padding: 3px;
}

.off-percentage {
  display: flex;
}

.off-percentage p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.off-percentage .plus-icons {
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-primary);
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 10px;
}

.shop-item-rating {
  display: flex;
}

.shop-item-rating p {
  margin-bottom: 0;
  margin-top: 3px;
  margin-left: 10px;
}

.shop-modal-box-cart .item-image img {
  width: auto;
}

.total-price h4 {
  color: var(--portal-theme-primary);
}

.time-zone-box input {
  color: var(--input-text-color);
}

.question-description-top-line {
  height: 4px;
  width: 25px;
  margin-bottom: 20px;
  border: 1px solid var(--portal-theme-primary);
  background-color: var(--portal-theme-primary);
}

.question-description-top-box p {
  font-size: 1rem;
}

/* question section  */

.top-banner-section {
  /* / background: #f6f7f9; /
  / background: black; / */
  /* background-image: url("/images/Questionpagebg.png") */
  background-position: center;
  background-size: cover;
}

.spaces {
  padding-top: 100px;
}

.answer {
  width: 100%;
  height: 20px;
}

.selectedAnswer {
  background-color: var(--portal-theme-secondary);
}

/* .question-answer:active {
  background-color: #B59D4E;
} */
.top-banner-section .right-part {
  /* / background-color: #fff; /
  / border: 1px #e1e5e7 solid; /
  / background-color: black; / */

  /* / border: 1px solid; /
  / border-radius: 10px; / */
  padding: 25px;
  z-index: 10;
}

.right-part h2 {
  color: white;
}

.right-part p {
  color: #b59d4e;
}

.questions-wrapper .question-card {
  border: 1px #b59d4e solid;

  /* / padding: 25px; / */
  z-index: 10;
  color: white;
  /* / background: #f6f7f9; / */
}

.questions-wrapper .question-card h6 {
  color: #b59d4e;
  border-bottom: 1px solid #ccb668;
  padding: 17px 21px;
  margin-bottom: 0px;
}

.faq-wrapper .faq-card {
  border: 1px #e1e5e7 solid;
  border-radius: 20px;
  padding: 25px;
  z-index: 10;
  background: #f6f7f9;
}

.faq-wrapper .faq-card p {
  font-size: 14px;
}

.assessment-question h6 {
  font-size: 1.2rem;
  letter-spacing: 1.3px;
  font-weight: 600;
}

.mediation-text h6 {
  font-size: 16px;
  color: #ccc9c4;
  font-family: "Inter", sans-serif;
}

.assessment-question .MuiFormControlLabel-label {
  font-family: "Montserrat";
  line-height: 1.4;
  font-weight: normal !important;
  font-size: 16px;
  letter-spacing: 1.3px;
}

.theme-radio {
  color: #cea254 !important;
}

.top-vedio-container {
  /* / padding-top: 145px; / */
  border-radius: 10px;
  /* background-image: url("/images/Rectangle.jpg"); */
  background-size: cover;
  background-position: center;
}

.inner-container {
  margin: 77px 5px 10px 2px;
  color: white;
  background: transparent;
}

.scale-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.description-container {
}

.scale-index {
  margin-top: 5px;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 500;
  border-top: solid 1px #cea25450;
  border-bottom: solid 1px #cea25450;
  cursor: pointer;
}

.scale-index:hover {
  background-color: #cea254;
}

.scale-border-left {
  border-left: solid 1px #cea25450;
}

.scale-border-right {
  border-right: solid 1px #cea25450;
}

.take-assessment-wrapper {
  min-height: calc(100vh - 110px);
}

.position-centered-rel {
  position: relative;
}

.position-centered-abs {
  position: absolute;
  /* top: 50%; / position the top  edge of the element at the middle of the parent /
  left: 50%; / position the left edge of the element at the middle of the parent / */
  transform: translate(-50%, -50%);
}

.btn-spacing {
  padding: 20px 20px;
  background: white;
  border: solid 5px #cea254;
  border-radius: 15px;
}

.assessment-text-area {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 4px;
  background-color: var(--sidebars-background-color);
  font-size: 16px;
  resize: none;
  color: white;
}

.black-btn {
  background-color: #ccb668;
  color: black;
  padding: 10px 55px;
  font-weight: 500;
  border: none;
  border-radius: 50px;
}

.question-answer {
  /* border-bottom: 1px solid #ccb668; */
  padding: 11px;
}

.question-answer:hover {
  background-color: var(--portal-theme-secondary);
}

.color {
  background-color: #f6f7f9;
}

.selected-option {
  background-color: green;
}

/* .question-card .css-1nrlq1o-MuiFormControl-root{
  width: 100% !important;
} */
.MuiFormControl-root {
  width: 100% !important;
}

@media screen and (max-width: 780px) {
  .scale-index {
    margin: 5px;
    width: 65px;
    height: 65px;
    border: solid 1px white;
  }
}

@media screen and (max-width: 768px) {
  .position {
    left: 0px !important;
  }

  .spaces {
    padding-top: 75px;
  }
}

.attach-with-iframe {
  margin-right: -20px;
}

.qustion-section-summary p {
  color: var(--portal-theme-primary);
}

.top-vedio-container .wrapper .player iframe {
  position: absolute;
  top: 0px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgb(181, 157, 78);
}

/* question section end */
.home-page-plan ul {
  list-style-type: none;
  padding-left: 0;
}

.home-page-plan ul li {
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 22px;
}

.home-page-plan ul .tick {
  background: url(../images/Vector-tick.png) no-repeat left 4px;
}

.home-page-plan ul .cross {
  background: url(../images/cross-plan-icon.png) no-repeat left 5px;
}

.habit-days ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px;
}

.habit-days ul li {
  display: inline-block;
  margin-right: 20px;
  width: 25px;
  height: 30px;
  text-align: center;
  padding-top: 5px;
  background-color: var(--portal-theme-secondary);
  border-radius: 3px;
}

.habits-slider .MuiSlider-markLabel {
  left: 97%;
  font-size: 16px;
}

.habits-slider .MuiSlider-valueLabel {
  font-size: 16px;
}

.silder-value {
  padding-top: 5px;
  margin-right: 10px;
}

.habits-gray-description {
  color: var(--text-secondary-color);
  font-weight: normal;
}

.frequency-labels .MuiCheckbox-colorPrimary {
  padding: 5px;
}

.frequency,
.habit-reminder {
  padding-left: 10px;
}

.frequency p,
.habit-reminder p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

/* .habits-box {
  position: relative;
} */

.habits-box .remove-border {
  position: absolute;
  top: 10px;
  right: 5px;
}

.habit-day-box {
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  border-radius: 10px;
  font-size: 15px;
  text-transform: none;
}

.habit-day-box span {
  font-size: 16px;
  letter-spacing: 2px;
}

.habit-detail .detail-image {
  width: 150px;
  margin: 20px auto;
}

.habit-detail .detail-image img {
  border-radius: 10px;
}

.habit-name span {
  /* color: var(--portal-theme-primary) */
  color: var(--text-primary-color);
}

.habit-name p {
  color: var(--text-secondary-color);
}

.habit-name .detail-frequency ul {
  padding-left: 0;
  padding-top: 8px;
}
.self-button .black-btn {
  padding: 10px 25px !important;
}
.habit-name .detail-frequency ul li {
  background-color: var(--portal-theme-secondary);
  list-style-type: none;
  display: inline-block;
  width: 30px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  border-radius: 3px;
}

.change-weeks {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.change-weeks-bg {
  background-color: var(--portal-theme-secondary);
  padding: 5px;
  width: 100%;
  text-align: center;
  height: 35px;
  border-radius: 3px;
}

.change-weeks .arrow-icon {
  width: 10%;
}

.change-weeks .arrow-icon svg {
  font-size: 14px;
}

.change-weeks .week-text {
  width: 75%;
}

.change-weeks .week-text .change-weeks-bg {
  padding-top: 5px;
}

.habit-detail .weeks-days ul,
.detail-frequency ul {
  display: flex;
  justify-content: space-between;
}

.habit-detail .weeks-days ul li {
  height: 60px;
  width: 35px;
  font-size: 17px;
  margin-right: 0;
  padding-top: 5px;
}

.habit-detail .weeks-days ul li p {
  color: var(--text-primary-color);
}

.habit-detail .weeks-days ul li svg {
  fill: var(--portal-theme-primary);
  font-size: 20px;
}

.habit-detail .card {
  background-color: var(--portal-theme-secondary) !important;
}

.habit-detail .notes-input {
  padding: 10px;
  padding-right: 20px;
}

.habit-detail .notes-input .normal-text {
  text-align: left;
  color: var(--text-primary-color);
}

.habit-detail .notes-input .date-color {
  text-align: right;
  margin-top: 16px;
}
.success {
  color: #229a16;
  background-color: rgba(84, 214, 44, 0.16);
}
.error {
  color: rgb(216, 55, 55);
  background-color: rgba(255, 72, 66, 0.16);
}
.cancel {
  color: white;
  background-color: rgba(216, 216, 216, 0.16);
}

.habit-detail .notes p {
  color: var(--text-primary-color);
  margin-bottom: 10px;
}

.habit-detail .notes a {
  color: var(--portal-theme-primary);
}

.popup-detail-box {
  height: 100vh;
  overflow: hidden;
}

.detail-page-box {
  height: 90vh;
  overflow: auto;
}
.MuiOutlinedInput-input {
  color: var(--input-text-color) !important;
}

.detail-page-box .profile-main-comments {
  height: 210px;
  overflow: auto;
}

/* .popup-detail-box .profile-main-comments {
  height: 315px;
  overflow: auto;
} */

#jitsi-meet-box {
  height: 100% !important;
  position: relative;
}

.jitsi-control {
  color: #fff;
  position: absolute;
  border: none;
  bottom: 20px;
  padding: 8px;
  border-radius: 3px;
}

.button-handler .control-call-end {
  background-color: red;
  left: 20px;
}

.button-handler .control-audio {
  background-color: var(--portal-theme-primary);
  left: 45%;
}

.button-handler .control-video {
  background-color: var(--portal-theme-primary);
  right: 20px;
}

/* assessment-wrapper Page css */
.assessment-wrapper h1,
.assessment-wrapper h2 {
  font-size: 30px;
  color: #ffffff;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 0;
}

.assessment-wrapper h2 {
  font-size: 24px;
  color: #ffffff;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

.vision-div h4 {
  color: #ccc9c4;
  font-size: 16px;
  padding-left: 5px;
  margin-bottom: 0px;
}

.assessment-wrapper iframe {
  border-radius: 15px;
  max-width: 100%;
}

.top-section-video iframe {
  border-radius: 15px;
  max-width: 100%;
}

.assessment-wrapper-description h2 {
  color: #b59d4e;
  font-size: 30px;
}

.assessment-wrapper label {
  color: #ffffff;
  font-size: 12px;
}

.label-text h4 {
  font-size: 18px;
  color: #ccc9c4;
  font-weight: 600;
  margin-bottom: 16px;
}

.text-white-label h4 {
  color: #fff;
  font-size: 16px !important;
}

.label-text h3 {
  font-size: 20px;
  color: #fff !important;
  margin-bottom: 20px !important;
}

.label-text p {
  color: #ffffff;
}

.assessment-wrapper .pt-70 {
  padding-top: 70px;
}

.assessment-wrapper .pt-120 {
  padding-top: 120px;
}

.assetssment-para h3 {
  font-size: 24px;
  color: #ccc9c4;
  font-family: "Inter", sans-serif;
  margin-bottom: 16px !important;
}

.assetssment-para p {
  font-size: 16px;
  font-weight: 400;
  color: #ccc9c4;
}

.diary-para p {
  font-size: 16px;
  font-weight: 400;
  color: #ccc9c4;
  margin-top: 20px;
}

.assetssment-tab-para h4 {
  font-size: 20px;
  margin-bottom: 16px;
  color: #ffffff;
}

.assetssment-tab-para p {
  font-size: 14px !important;
  /* color: #ccc9c4; */
}

.assesment-list li {
  background: #141717;
  border: 1px solid rgba(71, 66, 34, 0.3);
  padding: 0px 14px;
  padding-bottom: 14px;
  border-radius: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #ccc9c4;
  margin-top: 16px;
}

.assesment-list li > span {
  padding-right: 20px;
  margin-top: 14px;
}

.assesment-list li .FaStar-button {
  margin-top: 14px;
}

.star {
  display: inline;
  cursor: pointer;
}

.star > button {
  color: #b59d4e;
  background: transparent;
  border: 1.5px solid #b59d4e;
  border-radius: 50%;
  margin: auto 3px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
}

.star > button p {
  font-size: 12px;
  font-weight: 600;
}

.assessment-btn button {
  background: #b59d4e;
  color: #000000;
  padding: 8px 40px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  border: none !important;
  outline: none !important;
}

.assessment-btn-tab button {
  background: #b59d4e;
  color: #000000;
  padding: 12px 25px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  outline: none;
  border: none;
}

.btn-active {
  background: #b59d4e !important;
  color: #141717 !important;
  font-weight: 600 !important;
  font-size: 14px;
  border-radius: 50px;
}

.assesment-tab-btn {
  background: #141717;
  color: #ccc9c4;
  border-radius: 50px;
  font-size: 14px;
  padding: 8px 30px;
  font-weight: 500;
  border: none !important;
  outline: none !important;
}

@media (max-width: 912px) {
  .assessment-wrapper iframe {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 525px) {
  .assessment-wrapper iframe {
    max-width: 100%;
    height: auto;
  }

  .assesment-tab-btn {
    padding: 8px 20px;
  }

  .assessment-wrapper h1 {
    font-size: 20px;
  }
}

.result-div {
  background: #202220;
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clock-box {
  background: linear-gradient(0deg, #b59d4e 50%, #d4b85b 0);
  border-radius: 8px;
  padding: 25px 25px 20px 15px;
}

.dots-timer {
  font-size: 24px;
}

.text-para {
  color: #ccc9c4;
  font-size: 15px;
  margin-bottom: 0px !important;
  padding-top: 8px !important;
  font-weight: 400;
}

.clock-box p {
  color: #000608;
  font-size: 34px;
  margin-bottom: 0px !important;
}

.result-div h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #ffffff;
  padding-top: 5px;
}

.result-div p {
  font-size: 4px;
  font-weight: 600;
  margin-bottom: 0 !important;
  color: #ffffff;
}

.bg-color-div {
  background: #b59d4e;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-color-div i {
  font-size: 28px;
  color: #000608;
}

.assessment-result-box {
  padding: 15px 20px;
}

.btn-yellow {
  background: #f5f17f;
}

.btn-yellow h4 {
  color: #000609 !important;
}

.assessment-result-box h4 {
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 16px;
}

.assessment-result-box p {
  font-size: 12px;
  /* color: #000609; */
  font-weight: 400;
  margin-bottom: 0;
}

.border-div-assessment {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #1a1c17) !important;
  border: 1px solid rgba(181, 157, 78, 0.81);
  padding: 30px 0;
  border-radius: 8px;
}

.border-div-assessment h6 {
  color: #b59d4e;
  font-weight: 400;
  font-size: 14px;
}

.border-div-assessment img {
  display: block;
  margin: 0 auto;
}

.z-index-10 {
  z-index: 10;
}

.borderbg-top {
  border-top: 1px solid rgba(181, 157, 78, 0.81);
  padding: 20px 0;
}

.event-locotion-container {
  padding: 22px 25px 0px;
}

.time-count-container {
  border: 1px solid #b59d4e;
  padding-bottom: 3px;
  border-radius: 5px;
  border-top: none;
  position: relative;
}

.timer-count {
  text-align: center;
  font-size: 28px;
  color: #000;
  font-weight: 600;
}
.remove-access-google svg {
  fill: red !important;
}
.remove-access-google p {
  color: #cdc9c9;
  font-size: 12px;
}

.timer-count .count {
  width: 55px;
  padding: 20px 0px;
  border-radius: 5px;
  background: linear-gradient(#d4b85b 50%, #b59d4e 50%);
}
.timer-count .countEvent {
  width: 45px;
  padding: 15px 0px;
  border-radius: 5px;
  background: linear-gradient(#d4b85b 50%, #b59d4e 50%);
}

.dashboard-notification-timer .timer-count {
  justify-content: space-between;
}

.dashboard-notification-timer .timer-count .count {
  width: 40px;
  padding: 14px 0px;
  font-size: 20px;
}

.dashboard-notification-timer .time-count-container {
  height: 62px;
}

.dashboard-notification-timer h2 {
  color: #fff;
  text-align: center;
}

.timer-count .colon {
  padding-top: 17px;
  color: rgba(204, 201, 196, 0.9);
  font-size: 35px;
}

.dashboard-notification-timer .timer-count .colon {
  font-size: 20px;
}

.dashboard-program-description p {
  text-align: justify;
}

@media (max-width: 912px) {
  .timer-count .count {
    width: 40px !important;
  }
  .dashboard-notification-timer .timer-count .count {
    width: 32px !important;
    padding: 10px 0px;
  }
  .dashboard-notification-timer .time-count-container {
    height: 54px;
  }
}

.time-detail {
  width: 100%;
  text-align: center;
  color: #ccc9c4;
  font-size: 16px;
}

.time-container-site {
  padding: 14px 10px 0px;
}

@media (max-width: 1024px) {
  .timer-count .count {
    width: 59px;
    padding: 16px 0px;
    background-color: #b59d4e;
    border-radius: 5px;
  }
}

.visioneer-btn a {
  background: #b59d4e;
  color: #000608;
  font-size: 14px;
  padding: 8px 35px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
}

.check-box-div {
  background: #202220;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.check-box-lable label {
  font-size: 12px;
  color: #ccc9c4;
  font-weight: 500;
}

.w-check-50 {
  width: 65px;
}

#inputOne + label {
  background: transparent;
  border: 1px solid #b59d4e;
  border-radius: 50%;
}

#inputTwo + label {
  background: transparent;
  border: 1px solid #b59d4e;
  border-radius: 50%;
}

.css-xzqsgo {
  display: none !important;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: block;
  border: solid 5px #fff;
  border-radius: 50%;
  box-shadow: 0 0 1px #fff;
  cursor: pointer;
  margin: 10px;
  transition: box-shadow 0.4s, border 0.4s;
  height: 35px;
  width: 35px;
}

input[type="checkbox"] + label:hover,
input[type="checkbox"]:checked + label {
  border: 5px solid #b59d4e;
  box-shadow: 0 0 1px #b59d4e;
}

input[type="checkbox"]:checked + label::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 28px;
  color: #99b83c;
  content: "\2714";
  line-height: 1;
  margin: auto;
  text-align: center;
  height: 1em;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  color: white;
  font-family: "Montserrat";
  background-color: #141717;
  border: 1px solid #474222;
  border-radius: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.upload-div {
  background: #141717;
  /* height: 200px; */
  width: 100%;
  margin: 20px 0;
}

.upload-div img {
  object-fit: cover;
  border: 0;
  height: 210px !important;
  width: 100% !important;
}

.upload p {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 1;
  font-size: 38px;
  transition: opacity 0.25s ease-in-out;
}

.text-white-label label {
  color: #ffffff;
}

.rang-slider-div .range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  background: #b59d4e;
  transform: translate(0, -50%);
  border-radius: 30px;
  width: 100%;
  height: 21px;
}

.rang-slider-div .range-slider .range-slider__thumb {
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50px;
  background: #000609;
  display: flex;
  justify-content: start;
  align-items: start;
  border: 3px solid #202220;
}

.rang-slider-div .range-slider .range-slider__thumb[data-disabled] {
  display: none;
  width: 0;
  height: 0;
}

.rang-slider-div .range-slider {
  width: 100%;
  height: 20px;
  background: #ffffff;
  outline: none;
  border-radius: 30px;
  opacity: 1;
  transition: opacity 0.2s;
}

.upload.is-highlight {
  background-color: #141717;
  border: 1px solid #474222 !important;
  border-radius: 8px;
}

.upload.is-highlight p {
  opacity: 1;
}

.upload.is-drop p {
  opacity: 1;
}

.upload-button-btn {
  position: absolute;
  bottom: 0;
  right: 12px;
}

.upload-file {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.button-upload-image {
  border-radius: 30px;
  padding: 6px 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #b59d4e;
  color: #0c0f0c;
  border: 0;
  font-weight: 600;
}

.assessment_filler_image .top-image {
  margin-left: -1px !important;
}

.coins-div h3 {
  margin-bottom: 0 !important;
}

.coins-div img {
  margin: 0 auto;
}

.coins-div p {
  font-size: 10px;
  white-space: nowrap;
}

.daily-message-card {
  background-color: var(--sidebars-background-color);
  padding: 10px;
  border-radius: 5px;
}

.message-share-button button {
  padding: 3px 8px;
  background-color: #fff !important;
  color: #000 !important;
  font-size: 11px;
  font-weight: 500;
  border: none !important;
  border-radius: 2px !important;
}

.message-share-button a {
  text-decoration: none !important;
}

.daily-message-card .message-title p {
  font-size: 12px;
  margin-bottom: 0;
}

.daily-message-card .message-title span {
  font-size: 10px;
}

.daily-message-card audio {
  height: 40px;
}

.visioneering-accepted {
  color: rgb(48 159 37);
}

.visioneering-complete-button button {
  background-color: var(--portal-theme-primary);
  color: #fff;
  padding: 3px 8px;
  border: none;
  border-radius: 3px;
}

.static-date-picker {
  width: 100%;
  background-color: transparent;
  /* padding-left: 3rem; */
}

.static-date-picker .MuiPickerStaticWrapper-content {
  background-color: transparent;
}

.static-date-picker .css-epd502,
.static-date-picker .MuiCalendarPicker-root,
.static-date-picker .MuiDayPicker-header {
  width: 100%;
}

.static-date-picker .MuiDayPicker-header,
.static-date-picker .MuiDayPicker-weekContainer {
  justify-content: space-between;
}

.static-date-picker .MuiPickersCalendarHeader-root {
  padding-left: 16px !important;
  padding-right: 7px;
}

/* .static-date-picker .MuiDayPicker-header {
  padding-left: 15px;
} */

.static-date-picker .MuiPickersDay-root,
.static-date-picker .MuiDayPicker-weekDayLabel {
  font-size: 1rem !important;
}

.visioneer-btn svg {
  height: 20px;
}

@media (max-width: 912px) {
  .static-date-picker {
    padding-left: 0;
    margin-top: 25px;
  }
}

.visioneering-list-description {
  cursor: pointer;
}

.visioneering-list-description h3 {
  color: #fff;
}
.box-user {
  min-height: 300px;
}
.visioneering-list-description p {
  font-weight: 400;
  margin-bottom: 50px;
}

.visioneering-textarea label {
  font-size: 14px;
}

.mui-slider .MuiSlider-thumb {
  color: black;
  border: 1px solid var(--portal-theme-primary);
}

.mui-slider .MuiSlider-markLabel {
  color: #fff !important;
}

.mui-slider .MuiSlider-markLabel[data-index="0"] {
  left: 17px !important;
  right: auto;
}

.mui-slider .MuiSlider-markLabel[data-index="1"] {
  left: auto !important;
  right: -23px;
}

.title-with-back-button {
  color: var(--portal-theme-primary);
  font-size: 23px;
  margin-left: 20px;
}

.MarkComplete {
  background: transparent;
  border: 1px solid #b59d4e;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.mark-complete-check-box-lable label {
  font-size: 12px;
  color: #ccc9c4;
  font-weight: 500;
  display: inline-block !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
}

.mark-complete-check-box-lable input[type="checkbox"]:checked + label::after {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 20px;
  color: #000;
  content: "\2714";
  line-height: 1;
  margin: auto;
  text-align: center;
  height: 1em;
}

.challenge-date span,
.challenge-day-in-tatal span {
  color: var(--portal-theme-primary) !important;
}

.mark-complete-check-box-lable button {
  background: #b59d4e;
  color: #000608;
  font-size: 14px;
  padding: 8px 35px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  border: none;
  outline: none;
}

.countdown-timer h4 {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0;
}

.attitude-assessment-not-found {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
}

.textarea-autosize {
  outline: none;
}

.text-spacing-gratitude-daily {
  line-height: 3;
  font-size: 14px !important;
}

.textarea-autosize:focus-visible {
  border: 2px solid var(--portal-theme-primary) !important;
}

.user-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  border: 1px solid var(--portal-theme-secondary);
}
.audiowave {
  align-items: center;
}
.user-search-input:focus {
  outline: none;
}

.user-search-box {
  position: relative;
}

.pinned-image {
  padding: 0 !important;
  border-bottom: 0 !important;
  cursor: pointer;
}

.mui-slider .MuiSlider-valueLabelOpen {
  background-color: var(--sidebars-background-color);
  padding: 4px 10px;
}

.show-comment-names {
  background-color: #333232;
  color: #fff;
  border-radius: 3px;
}

.show-comment-names-box {
  position: relative;
}

.show-comment-names {
  position: absolute;
  left: 0;
}

.likes-model-tabs {
  padding: 10px;
}

.likes-model-tabs img {
  margin-right: 8px;
  width: 16px;
  height: 14px;
  margin-top: 2px;
}

.feed-activity-box {
  max-height: 350px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed-activity-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed-activity-box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.user-info-card .date-color {
  font-size: 12px !important;
}

.leading-section span {
  font-size: 16px !important;
  color: #c2a366 !important;
  font-weight: bold;
}

.leading-section button {
  padding-top: 17px;
}

.leading-section .slick-prev {
  left: -34px;
  top: 75px;
}

.leading-section .slick-next {
  right: -34px;
  top: 70px;
}

.leading-section .image-box {
  height: 45px;
  /* width: 45px; */
  /* border: 1px solid #fff; */
  border-radius: 50%;
  text-align: center;
  /* background: #000; */
  padding-top: 25px;
  /* position: absolute; */
  top: 4px;
  text-align: right;
}

.leading-section .image-box-border {
  height: 45px;
  width: 45px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
  text-align: center;
  background: #000;
  padding-top: 7px;
  position: absolute;
  top: 4px;
}

.leading-section .image-box img {
  height: auto;
  width: 40px;
  display: inline-block;
}

.image-coin-box,
.road-map-item {
  position: relative;
}

.leading-section .slick-slide > div {
  margin: 0;
}

.road-map-item {
  background-image: linear-gradient(180deg, #181308, #000);
  /* padding-top: 32px !important; */
  text-align: right;
}

.road-map-item span {
  margin-top: 15px;
}

.road-map-item .road-map-title {
  position: absolute;
  top: -51px;
}

.road-map-item .road-map-icon svg {
  font-size: 50px;
  color: #c2a366;
  fill: #c2a366;
}

.road-map-item .road-map-icon p {
  white-space: nowrap;
  margin-bottom: 0;
}

.road-map-item .road-map-icon {
  text-align: center;
  position: absolute;
  top: -60px;
  margin-left: 0 !important;
  z-index: 111111;
}

.show-less-more-box {
  position: relative;
}
.show-less-more:hover {
  opacity: 0.5;
}
.show-less-more {
  position: absolute;
  bottom: 0px;
  right: 0;
  font-size: 13px;
  color: var(--portal-theme-primary);
  transition: 0.2s all;
}

.road-map-item .road-space {
  left: 14% !important;
}

.leading-section .slick-track {
  padding-top: 40px;
  padding-bottom: 30px;
}

/* .road-map-active-item .image-box {
  border: 1px solid #c2a366;
  padding-top: 8px !important;
} */

.road-map-coins-count {
  font-weight: 600;
  font-size: 15px;
}

.dotted-lines {
  border-bottom: 3px dotted #fff !important;
  /* padding-top: 11px; */
}

.dotted-lines-colored {
  border-bottom: 3px dotted #c2a366 !important;
  /* padding-top: 11px; */
}

.waveButton {
  background: OrangeRed;
  color: white;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  outline: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.controls {
  text-align: left;
  padding: 1em;
}

.waveAudio {
  height: 200px;
}

.rangeVolume {
  color: var(--portal-theme-primary);
}

.visioneering-box {
  position: relative;
}

.view-challenge-button-box {
  position: absolute;
  left: 12px;
  bottom: 0;
}

.leader-board-popup {
  top: 20px !important;
  left: -20px !important;
}

.legend-title .color-box {
  height: 14px;
  width: 10%;
  margin-top: 3px;
  margin-right: 15px;
}

.legend-title .description-box {
  width: 90%;
  font-size: 16px !important;
}

.btn-btn-portal-navigate {
  cursor: pointer;
}

.ninty-day-slider {
  width: 90%;
}

.full-open-calender .MuiCalendarPicker-root {
  background-color: transparent !important;
}

.notifications-circle {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 10px;
  margin-right: 5px;
}

.notifications-circle-seen {
  color: rgb(51 56 61 / 8%);
  fill: rgb(51 56 61 / 8%);
}

.notifications-delete-button {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 18px;
  visibility: hidden;
}

.notification-item-box {
  display: flex;
  margin-bottom: 1px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.notification-item:hover .notifications-delete-button,
.notification-item-unread:hover .notifications-delete-button {
  visibility: visible;
}
/* .notification-item:hover .notifications-delete-button,
.notification-item-unread:hover .notifications-delete-button {
  visibility: visible;
} */

.leading-section .before-slider-empty-div {
  position: absolute;
  width: 43px;
  height: 90px;
  background-image: linear-gradient(180deg, #181308, #000);
  margin-top: 40px;
}

.leading-section .after-slider-empty-div {
  z-index: -1;
  position: absolute;
  width: 57px;
  right: 0;
  top: 0;
  height: 90px;
  background-image: linear-gradient(180deg, #181308, #000);
  margin-top: 40px;
}

.leading-section .empty-right-dev {
  position: absolute;
  width: 43px;
  height: 130px;
  background-image: linear-gradient(180deg, #181308, #000);
  margin-top: 40px;
}

.road-map-item-last {
  margin-right: 60px;
}
.road-map-item-first {
  margin-left: 53px;
}

@media (max-width: 912px) {
  .road-map-item-last {
    margin-right: 73px;
  }
  .leading-section .after-slider-empty-div {
    width: 73px;
  }
}

.feed-level .MuiChip-root {
  color: var(--portal-theme-primary) !important;
  height: 16px;
  font-size: 10px;
  margin-top: 2px;
}

.dynamite-level-image {
  height: 30px !important;
  width: 34px !important;
  margin-top: -4px;
  padding-left: 6px;
  padding-top: 3px;
}

.community-level select {
  color: #fff;
  padding: 2px 4px;
  background-color: transparent;
  border: 1px solid var(--portal-theme-primary);
  outline: none;
  border-radius: 3px;
}

.community-level select option {
  background-color: var(--sidebars-background-color) !important;
}

.dashboard-card {
  box-shadow: none;
  color: #fff;
  background-color: var(--sidebars-background-color);
  border-radius: 16px;
  padding-top: 24px;
}

.dashboard-stats-card {
  box-shadow: none;
  color: #fff;
  background-color: var(--sidebars-background-color);
  border-radius: 16px;
  padding-top: 24px;
  text-align: center;
}

.dashboard-stats-card img {
  display: inline;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.dashboard-stats-card .title {
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  margin: 0;
  color: #6c757d;
}

.dashboard-card .title {
  color: var(--portal-theme-primary);
  font-size: 16px;
  margin-left: 24px;
  margin-bottom: 0 !important;
}
.dashboard-card .text-section {
  margin-top: 24px;
}
.dashboard-card .main-box {
  padding-right: 24px;
  padding-left: 24px;
}

.dashboard-card .icon-wrapper-style {
  margin: auto;
  display: flex;
  border-radius: 50%;
  align-items: center;
  padding: 24px;
  margin: auto auto 24px;
  width: 64px;
  height: 64px;
  justify-content: center;
  background-image: linear-gradient(
    135deg,
    rgba(34, 154, 22, 0) 0%,
    var(--portal-theme-secondary) 100%
  );
}

.dashboard-card h3 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.dashboard-card .date-color {
  font-weight: normal !important;
  font-size: 15px !important;
}

.dashboard-notification-card {
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  padding: 14px 10px;
  height: 100%;
}

.dashboard-notification-card .warning-icon {
  width: 30px;
  height: 30px;
}
.dashboard-notification-card h3 {
  font-weight: 500;
  font-size: 15px;
}

.dashboard-notification-card p {
  font-size: 16px;
  color: var(--text-secondary-color);
}

.dashboard-notification-card a,
.dashboard-notification-card button {
  border: 1px solid var(--portal-theme-primary);
  background-color: transparent;
  outline: none;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  padding: 6px 8px;
  text-decoration: none;
}

.dashboard-notification-card a {
  display: inline-block;
}

.dashboard-chart .title {
  color: #fff;
}

.notification-button button {
  border: 1px solid var(--portal-theme-primary);
  background-color: transparent;
  outline: none;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  padding: 3px 8px;
}

.dashboard-progress-icon {
  height: 80px !important;
  width: 80px !important;
}

.dashboard-progress-box {
  position: relative;
}

.dashboard-progress-text {
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  text-align: center;
}

.dashboard-progress-text-title {
  font-size: 20px;
}

.starting-image {
  width: 35px;
  height: auto;
  margin: 32px 5px 0 auto;
}

.end-slider-image {
  width: 35px;
  height: auto;
  margin: 32px auto 0 0;
}

.days-in-total-text {
  font-size: 15px;
}

.days-in-total-text span {
  color: var(--portal-theme-primary);
  font-weight: bold;
}
.dashboard-lessons-box {
  height: 300px;
  overflow-y: auto;
}

.source-session h3 {
  font-weight: normal;
  font-size: 15px;
}

.source-session p {
  font-size: 14px;
  color: var(--text-secondary-color);
}

.dashboard-card .timer-box {
  margin-top: 24px;
  background-color: var(--portal-theme-secondary);
  padding: 10px 24px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 16px;
}

.dashboard-card .timer-box p {
  margin-bottom: 0 !important;
}
.event-config-time {
  font-weight: 500;
  font-size: 22px;
}
.centerAlign {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: -10%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 60%; */
  justify-content: center;
  align-items: center;
}
.centerAlign0 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 30%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 60%; */
  justify-content: center;
  align-items: center;
}
.MuiClockPicker-root {
  background-color: var(--sidebars-background-color);
}

.MuiClockPicker-root span {
  color: #fff;
}

.MuiClockPicker-root .MuiClock-amButton:hover,
.MuiClockPicker-root .MuiClock-pmButton:hover {
  background-color: var(--portal-theme-primary);
}

.reminder-time-form input {
  color: white;
}

.reminder-time-form .submit-button {
  padding: 0px 20px;
}
.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.pagination-style button {
  background-color: var(--portal-theme-secondary);
  color: #fff;
}

.pagination-space-from-bottom p {
  margin-bottom: 0px !important;
  color: #fff;
}
.MuiTableSortLabel-root,
.MuiTableCell-root,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiSelect-select {
  color: white;
}
/* .MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: white !important ;
} */
.dashboard-program-timer-box {
  display: flex;
  align-self: center;
}

/* mui-bg-custom-table */
.mui-bg-custom-table {
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.mui-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
  color: #fff;
}
.mui-bg-custom-table .MuiTablePagination-actions svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}
.MuiTableCell-root {
  color: #fff;
  background-color: var(--sidebars-background-color) !important;
}
.MuiToolbar-root input {
  color: #fff;
}

.MuiPopover-paper {
  background-color: var(--sidebars-background-color) !important;
  color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
/* .cursor-pointer:hover {
  color: var(--portal-theme-primary) !important;
} */
.cursor {
  cursor: pointer;
  color: var(--text-primary-color) !important;
}
.cursor:hover {
  color: var(--portal-theme-primary) !important;
}
.css-1esf93k-MuiButtonBase-root-MuiChip-root,
.css-11mo73z-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color);
}

.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper,
.css-1clbjpy {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.autocomplete-paper,
.autocomplete-mui {
  background-color: var(--sidebars-background-color) !important;
  color: var(--input-text-color);
}

.selected-booking-detail span {
  display: block;
  color: var(--text-secondary-color);
  margin-bottom: 1rem;
}

.disabled-lesson {
  cursor: not-allowed;
}
.new-message-search-box {
  position: relative;
}

.new-message-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}
.new-message-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  border: 2px solid var(--portal-theme-secondary);
}

.new-message-search-input:focus {
  outline: none;
}

.message-user-avatar img {
  border-radius: 50%;
}

.message-user-box {
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  cursor: pointer;
}

.message-user-box p {
  margin-bottom: 0;
}
.css-1c1nuln {
  background-color: transparent !important;
  color: rgb(255, 255, 255) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
}
.sale-page-title {
  color: var(--portal-theme-primary);
  font-size: 23px;
  margin-left: 20px;
}

.general-questions-top-description img {
  display: inline !important;
}
.scriptSelectedImage {
  opacity: 0.2;
}
em-emoji-picker {
  width: 100%;
}

.emoji-picker-box em-emoji-picker {
  position: absolute;
  z-index: 1;
}

em-emoji-picker .flex-column .scroll + div {
  width: 100% !important;
}

.emoji-picker-popup {
  width: 300px;
}

.emoji-picker-popup em-emoji-picker {
  height: 200px;
}

.button-link a {
  color: var(--portal-theme-primary);
}
.button-link span {
  right: -3px !important;
}

.category {
  width: 100% !important;
}

.generating-pdf {
  padding-top: 0px;
  width: 400px !important;
  white-space: normal;
  font-family: serif;
  line-height: 11px;
}

.generating-pdf .logo-dd {
  max-width: 150px;
}

.generating-pdf .last-submit-date {
  font-size: 10px;
  margin-top: 3px;
}

.generating-pdf .general-question-heading {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.question-statement-for-pdf-text {
  width: 400px !important;
  white-space: normal;
  font-family: serif;
  line-height: 11px;
}

.question-statement-for-pdf-text p {
  margin-bottom: 10px !important;
}

.text-area-answer {
  white-space: normal;
  width: 400px !important;
}

.generating-pdf,
.generating-pdf h4 {
  font-size: 9px !important;
  word-spacing: 0px !important;
  letter-spacing: 0.01px !important;
  white-space: nowrap !important;
  text-align: left;
  font-family: serif;
  line-height: 11px;
}

.generating-pdf h4 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.generating-pdf ul {
  padding: 0 !important;
  font-family: serif;
  line-height: 11px;
}

.generating-pdf .question-statement-for-pdf {
  white-space: nowrap !important;
  font-size: 9px !important;
  /* color: var(--portal-theme-primary); */
  margin-bottom: 4px !important;
}

.view-document-in-pdf {
  padding: 2px 5px;
  outline: none;
  /* border: 1px solid #000;
  color: #000; */
}

.generating-pdf .radio-buttons input {
  width: 8px;
  height: 8px;
  background-color: #fff;
  margin-top: 8px;
  margin-right: 4px;
}

.scaling-count {
  display: inline-block;
  width: 12px;
  height: 12px;
  text-align: center;
  border-radius: 50%;
  margin-right: 2px;
  border: 1px solid #000;
  background-color: #fff;
  padding: 0;
}

.scaling-count-selected {
  background-color: #000;
  color: #fff;
}

.scaling-count p {
  margin-top: -5px;
  font-size: 6px;
  margin-bottom: 0;
}

.generating-pdf input[type="checkbox"] {
  display: block;
  height: 8px;
  width: 8px;
  background-color: #000;
  margin-top: 8px;
  margin-right: 4px;
}
.create-post-images span {
  right: 13px;
}

.create-post-popup {
  max-height: 100vh;
}

.more-images-count-box {
  text-align: center;
  position: relative;
}
.more-images-count {
  position: absolute;
  top: 35px;
  font-size: 30px;
}
.clear-both {
  clear: both;
}

.perform-action-on {
  min-width: 400px;
  max-width: 100%;
}
.perform-action-on label {
  color: #fff;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning,
.tox-statusbar {
  display: none !important;
}
.general-popup-model {
  background-color: var(--sidebars-background-color) !important;
}

.general-popup-model hr {
  background-color: #fff;
  color: #fff;
}
#show-dialog-on-full .MuiDialog-paperScrollPaper {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  margin: 0 !important;
  position: relative;
}
.calendar-icon-date,
.css-gvm4fs {
  font-size: 12px !important;
  font-size: 17px !important;
}
.event-submit-button {
  background-color: var(--portal-theme-primary);
  color: #fff;
}
.event-title-dot {
  height: 10px;
  width: 10px;
  margin-top: 4px;
  margin-right: 3px;
  border-radius: 50%;
}

.calender-title {
  color: #fff;
}
.update-stripe-card .rccs,
.update-stripe-card .rccs__card {
  width: 100% !important;
}

@media (max-width: 768px) {
  .calender-title {
    display: none !important;
  }
}

.template .question {
  word-spacing: 0px !important;
}

.graphics-links img {
  height: 60px;
  object-fit: cover;
}

.graphics-links h4 {
  color: var(--portal-theme-primary);
}

.graphics-links-height-dev {
  height: 100%;
  overflow: auto;
}
.graphics-links-height {
  height: 230px;
  overflow: auto;
}
.download-graphic {
  position: relative;
}
.title {
  margin-top: 18px;
  margin-left: 20px;
  text-transform: capitalize;
  color: var(--portal-theme-primary);
}
.download-graphic .icon-style {
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  right: 7px;
  top: 7px;
  z-index: 1;
}

.download-graphic .icon-style span,
.download-graphic .icon-style {
  width: 20px !important;
  height: 20px !important;
}

.notifications-setting h4 {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
}

.notifications-setting .notification-description p {
  font-size: 14px;
  color: var(--text-secondary-color);
}

.booking-status-chip {
  padding: 10px 0;
  width: 115px !important;
}
.supbscription-status-chip {
  color: #fff !important;
  padding: 10px 0;
  width: 115px !important;
}

.booking-timezone {
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
}

.question-statement-section p {
  font-size: 17px;
}

/* road-map css */
.road-map-scroll {
  padding-top: 25px;
  background-image: linear-gradient(180deg, #181308, #000);
  overflow: auto;
  white-space: nowrap;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 35px;
}

.road-map-scroll .road-map-scroll-div {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  height: 100px;
  position: relative;
}

.road-map-scroll-div-first {
  width: 145px;
  padding-left: 0 !important;
}

.road-map-scroll .road-map-scroll-div-start {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  width: 54px;
  padding-right: 0px;
  height: 100px;
  position: relative;
}

/* .road-map-scroll .road-map-scroll-div-start .road-map-scroll-image {
  border: none !important;
} */

.road-map-scroll .road-map-scroll-div-start img {
  display: block;
}

.road-map-scroll .road-map-scroll-left {
  position: absolute;
  top: 70px;
  left: -5px;
}

.road-map-scroll .road-map-scroll-right {
  position: absolute;
  top: 70px;
  right: -5px;
}

.road-map-scroll .road-map-scroll-icon {
  color: #000;
  background-color: #b88d38;
  border-radius: 50%;
  font-weight: 800;
  padding: 2px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
}

.road-map-scroll .road-map-scroll-icon:hover {
  background-color: var(--portal-theme-primary);
}

.road-map-scroll .gb-left {
  border-left: solid 2px #e4b047;
}

.road-map-scroll::-webkit-scrollbar {
  display: none;
}

.img-border {
  margin-top: -92px;
  margin-left: -10px;
}

.road-map-scroll-title-odd {
  position: relative;
  top: -5px;
  z-index: 30;
}

.road-map-scroll-title-even {
  position: relative;
  top: -5px;
  z-index: 30;
  right: 100px;
}
.road-map-scroll-image {
  text-align: right;
  position: relative;
  /* border-bottom: 3px dotted #c2a366; */
  padding-top: 30px;
}

.road-map-selected-image {
  display: inline-block;
  width: 140px;
  text-align: center;
}

.reduce-margin {
  margin-bottom: -20px;
}

.road-map-icon .title {
  margin: 5px 0 2px;
  color: #ffffff;
}

.road-map-icon {
  color: var(--text-secondary-color);
  font-size: 13px;
}

.road-map-selected-image img {
  width: auto;
  height: 40px;
  display: inline;
}

/* .border-white {
  border-bottom: 3px dotted #fff;
}

.border-primary-color {
  border-bottom: 3px dotted #c2a366;
} */

.road-map-scroll-image img {
  width: 40px;
  height: auto;
  display: inline;
  position: absolute;
  top: 11px;
}

.road-map-scroll-title {
  position: absolute;
  margin-top: 40px;
  max-width: 170px;
}

.float-left {
  float: left !important;
}

.person-pin-circle-icon {
  position: absolute;
  top: -45px;
}

.person-pin-circle-icon svg {
  font-size: 40px;
  color: #c2a366;
  fill: #c2a366;
}
.road_map_detail_description {
  height: 380px;
  overflow: auto;
}

.general-questions-list-drawer {
  width: 580px !important;
  max-width: 100% !important;
}

@media (max-width: 991px) {
  .road_map_detail_description {
    height: auto;
  }
}

@media (max-width: 571px) {
  .top-banner-section .right-part {
    padding: 0;
  }
}

iframe {
  max-width: 100% !important;
  width: 100% !important;
}

.outer-main {
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 2s ease-in-out;
}

.outer-main span {
  position: absolute;
  top: -10px;
}

.show-answers-detail-box {
  position: relative;
}

.show-answers-detail-box .answer-box {
  background-color: #000;
  position: absolute;
  top: 0;
  color: #fff;
  border: 1px solid #fafafa7d;
  z-index: 1;
  display: flex;
  padding: 7px 10px;
  border-radius: 2px;
}

.show-answers-detail-box .color-box {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

/* shop cart */
.cart__row {
  position: relative;
}
/* .border-cart {
  border: 1px solid var(--portal-theme-primary)50 !important;
} */

.cart__border {
  border-top: 1px solid rgb(241, 243, 244) !important;
}
.table-main {
  border-radius: 5px;
  background-color: var(--background-secondary-color);
}
.cart__image-wrapper a {
  display: block;
}
.cart__image-wrapper {
  width: 120px;
  height: auto;
}
.cart td {
  padding: 10px;
}

.cart .cart__meta {
  padding-right: 15px;
}

.cart th.cart__meta,
.cart td.cart__meta {
  text-align: left;
}

.cart__image-wrapper {
  width: 120px;
  height: auto;
}
.cart th {
  font-weight: 400;
  padding: 10px 15px 8px;
  letter-spacing: 1px;
}
@media only screen and (max-width: 767px) {
  .cart-flex-item {
    display: table-cell;
    min-width: 0;
  }
}

.cart-cross-icon {
  position: absolute;
  top: -25px;
  right: -34px;
  cursor: pointer;
  background-color: var(--portal-theme-secondary);
  border-radius: 20%;
  height: 37px;
  width: 36px;
  font-size: 15px;
  text-align: center;
  padding-top: 6px;
  padding-left: 0px;
}
.payment_box {
  width: 50%;
  float: right;
}
.cart__footer .solid-border {
  padding: 20px;
  margin-bottom: 20px;
}
.cart__footer .cart__subtotal-title,
.cart__footer .h5,
.cart__footer .h5 label,
.cart__footer h5,
.cart__footer h5 label {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.02em;
}
.cart-p {
  text-align: right;
}
.cartCheckout {
  width: 100%;
  padding: 13px;
}
.text-bold {
  font-weight: 700;
}

.shop-card-discription {
  margin-top: 6px !important;
  margin-bottom: 0 !important;
  --bs-text-opacity: 1;
  color: #6c757d !important;
  font-size: 13px;
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.order-table .table thead th {
  font-size: 13px;
  padding: 8px 5px 5px;
  color: var(--portal-theme-primary);
  font-weight: 500;
  /* border-bottom: 1px solid #ddd; */
}

.order-table .table td {
  font-size: 13px;
  padding: 8px 5px 5px;
}
.table > :not(:first-child) {
  border-top: 1px solid gray;
}

.detail-item-quantity-popover .item-quantity-popover {
  display: flex;
  border: 1px solid var(--portal-theme-primary);
  justify-content: space-between;
  padding: 0 0px;
  font-size: 16px;
  max-width: 122px;
  border-radius: 4px;
}
.item-quantity-popover span {
  text-align: center;
  width: 25px;
}
.detail-item-quantity-popover .item-quantity-popover input {
  width: 39px;
  background-color: transparent;
  outline: none;
  border-left: 1px solid var(--portal-theme-primary);
  border-right: 1px solid var(--portal-theme-primary);
  border-bottom: 0;
  color: var(--input-text-color);
  font-size: 14px;
  text-align: center;
}

.event-in-post {
  background-color: var(--background-primary-color);
  padding: 6px 6px 10px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  clear: both;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem;
}

.carttotla {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-right: 50px;
}
.carttotla h3 {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
}
.google-button {
  background: white;
  color: black;
  border: none;
  font-weight: 500;
}
.post-event-button {
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
}

@media only screen and (max-width: 571px) {
  .payment_box {
    width: 100%;
    float: none;
  }
  .dim-color {
    color: var(--portal-theme-primary) 50;
    border-color: var(--portal-theme-primary) 50;
  }
  .on-mobile {
    flex-direction: column;
    align-items: start;
  }
  .mobile-screen-text {
    margin-left: 13px;
    margin-top: 4px;
  }
}

.post-detail-image iframe {
  width: 100% !important;
}

.chat-send-input-editor .tox-menubar,
.chat-send-input-editor .tox .tox-toolbar__primary,
.chat-send-input-editor .tox .tox-toolbar,
.chat-send-input-editor iframe {
  background-color: var(--sidebars-background-color) !important;
}

.chat-send-input-editor button,
.chat-send-input-editor iframe,
.chat-send-input-editor svg {
  color: var(--text-primary-color) !important;
  fill: var(--text-primary-color) !important;
}
.chat-send-input-editor .tox-edit-area__iframe {
  color: #fff !important;
}

.chat-send-input-editor button {
  background-color: inherit !important;
}

.chat-send-input-editor button.tox-tbtn--enabled {
  background-color: var(--portal-theme-primary) !important;
}

.chat-send-input-editor .tox-tinymce {
  border-radius: 5px;
}
/* 
.chat-send-input-editor .tox-toolbar__group {
  margin-top: -39px !important;
  margin-left: 108px !important;
} */

.tox-collection__item-label h1,
.tox-collection__item-label h2,
.tox-collection__item-label h3,
.tox-collection__item-label h4,
.tox-collection__item-label h5,
.tox-collection__item-label h6,
.tox-collection__item-label pre,
.tox-collection__item-label p {
  color: #222f3e !important;
}

.chat-list-last-message h1,
.chat-list-last-message h3,
.chat-list-last-message h4,
.chat-list-last-message h5,
.chat-list-last-message h6,
.chat-list-last-message h2 {
  font-size: 14px !important;
}
.menu-icon-settings {
  background-color: transparent !important;
}

.menu-icon-settings svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.edit-profile {
  margin-left: 80px !important;
}
@media screen and (min-width: 300px) and (max-width: 1024px) {
  .settings-screen-small {
    flex-direction: column !important;
  }
  .settings-small-component {
    padding-top: 12px !important;
  }
}
.qustion-color p {
  color: white;
}

.art-thanks .art-thanks-div {
  background: linear-gradient(0.316turn, #1e1e1e, #1a1c17);
  border: 1px solid rgba(181, 157, 78, 0.81);
  border-radius: 8px;
  padding: 40px 30px 65px;
}
.art-thanks .border-line-thanks {
  background: rgba(181, 157, 78, 0.3);
  height: 1px;
  margin-bottom: 10px;
}
.art-thanks .thank-button {
  font-size: 15px;
  color: #000608;
  background: #b59d4e;
  text-decoration: none;
  padding: 12px 25px;
  font-weight: 600;
  border-radius: 8px;
  font-family: Montserrat;
}

.voice-recorder {
  text-align: center;
  padding: 20px;
}

.start-button,
.stop-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.stop-button {
  background-color: #f44336;
}

.audio-player {
  margin-top: 20px;
}

.sound-wave {
  border: 2px solid #4caf50; /* Change border properties */
  background-color: #f0f0f0; /* Change background color */
  border-radius: 8px; /* Add rounded corners */
  width: 100%; /* Make the canvas span the full width of its container */
  height: 100px; /* Set a fixed height for the canvas */
  display: none;
}

.recording-slider {
  width: 100%;
  background-color: var(--sidebars-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  margin-top: 2px;
}

.recording-slider .inner-slider {
  width: 84%;
  height: 7px;
  background-color: var(--portal-theme-secondary);
}

.recorded-audio {
  width: 10%;
  height: 100%;
  background-color: var(--portal-theme-primary);
}

.recorded-time {
  padding-left: 10px;
  color: var(--text-secondary-color);
  width: 53px;
}
.action-buttons {
  /* background-color: var(--portal-theme-secondary); */
  display: flex;
  /* height: 40px;
  margin-top: 2px;
  padding: 0 10px;
  margin-right: 5px;
  align-items: center; */
  margin-right: 5px;
}

.action-buttons svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  cursor: pointer;
}
.custom-audio-player svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  cursor: pointer;
}

.custom-audio-player {
  display: flex;
  align-items: center;
}

.custom-audio-player .played-audio {
  height: 100%;
  max-width: 100% !important;
  background-color: var(--portal-theme-primary);
  border-radius: 3px;
}

.custom-audio-player .full-player {
  width: 100px;
  height: 3px;
  background-color: #000;
  margin-left: 5px;
  border-radius: 3px;
}

.vedio-menu h4 {
  font-size: 14px;
  color: white;
  margin-bottom: 2px;
}
.vedio-menu {
  text-align: left;
}
.active-vedio {
  background: linear-gradient(0.316turn, #1e1e1e, #1a1c17);
  border-radius: 6px;
}
.vedio-menus :hover {
  background: linear-gradient(0.316turn, #1e1e1e, #1a1c17);
  border-radius: 6px;
}
.play-list {
  background: linear-gradient(0.316turn, #1e1e1e, #1a1c17);
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.upgrade-button {
  border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  padding: 4px 18px;
  /* color: var(--portal-theme-primary); */
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
  text-decoration: none;
  background-color: var(--portal-theme-primary);
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.upgrade-button a {
  color: #000;
  text-decoration: none;
}

.upgrade-button a:hover {
  color: white;
}

.lock-vedio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-10px, -23px);
  width: 35px;
  height: auto;
}
.lock-vedio-container {
  opacity: 0.5;
  background: linear-gradient(0.316turn, #1e1e1e, #1a1c17);
}

.audio-loader {
  height: 16px !important;
  width: 16px !important;
  margin-top: 5px;
}
.mentorship-image img {
  width: 100%;
  object-fit: cover;
  height: 60px;
}
@media only screen and (max-width: 571px) {
  .play-list {
    margin-top: 40px;
  }
  .vedio-menu {
    text-align: left;
    margin-top: 10px;
  }
}

.upgrade-button:hover {
  text-decoration: none !important;
  color: white;
}

.image-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image-loader h2 {
  color: #fff !important;
  font-size: 24px;
}

.image-loader img {
  width: 150px;
}
.image-loader h2 {
  color: #b59d4e !important;
  font-size: 20px;
}
.menuActives {
  background-color: transparent !important;
}
.upgrade-button {
  background: #b59d4e;
  border-radius: 30px;
  color: #000608;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 25px;
  text-decoration: none;
}
.video_section {
  border-radius: 20px;
  border: 2px solid var(--portal-theme-secondary);
  height: 100%;
}

.video_section.highlight_video {
  border: 2px solid var(--portal-theme-primary);
}
.video_section h3 {
  color: var(--portal-theme-primary);
}
.lock_btn button {
  background-color: #1c1c1c;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  padding: 8px 16px;
  text-decoration: none;
}

.lock_icon_button button {
  cursor: default !important;
}

.lock_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.video_img_dev img {
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}

.video_section .btn_pos_top {
  margin-top: 6rem;
}
.video_section .btn_pos {
  position: absolute;
  bottom: 20px;
}
.art_btn a,
.the-art-achievement .art_btn button {
  font-size: 16px !important;
  background: linear-gradient(
    180deg,
    var(--portal-theme-primary),
    #d0a448 28.12%,
    #ba8123 58.85%,
    #eab22e
  );
  color: #000;
  padding: 10px 30px;
  border-radius: 5px;
  letter-spacing: 1px;
  border: none;
  text-transform: uppercase;
  font-family: PaytoneOneRegular;
  text-decoration: none !important;
}
.general-popup-model-max-width {
  position: relative;
  max-width: 1000px;
  min-width: 1000px;
  z-index: 1200 !important;
}

.payment-plan-box {
  border: 1px solid #b59d4e;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.payment-plan-box .plan-title {
  background-color: #b59d4e;
  padding: 15px;
  padding-bottom: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
}
.payment-plan-box .plan-title-new {
  background-color: transparent !important;
  padding: 15px;
  padding-bottom: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.button-abs {
  position: absolute;
  bottom: 20px;
}

@media screen and (max-width: 999px) {
  .general-popup-model-max-width {
    position: relative;
    max-width: 1000px;
    min-width: 100%;
    z-index: 1200 !important;
  }
}

.payment-method {
  font-size: 18px;
}

.shopping-cart-checkout .payment-method {
  font-size: 14px;
}

.calculating-custom-duty {
  height: 14px !important;
  width: 14px !important;
}

.method-box {
  height: 18px;
  width: 18px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected-method {
  height: 12px;
  width: 12px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
}

.custome-radio-box {
  height: 18px;
  min-width: 18px;
  width: 18px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custome-checkbox-box {
  height: 15px;
  min-width: 16px;
  width: 16px;
  border: 2px solid var(--portal-theme-primary);
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 1px;
}

.custome-checkbox-box .fa-check {
  font-size: 15px;
  color: #000;
}

.custome-checkbox-box.selected {
  background-color: var(--portal-theme-primary);
}

.custom-duty-text.text-blur {
  opacity: 0.4;
}

.calculate-custom-tax {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.custome-radio-box .selected {
  height: 12px;
  width: 12px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
}

.pay-initiated-now {
  background-color: var(--portal-theme-primary);
  color: var(--text-primary-color);
  border: none;
  padding: 2px 9px;
  border-radius: 15px;
  height: 25px;
  font-size: 13px;
}

.tox-notifications-container {
  visibility: hidden !important;
}

.table-phone-input .react-tel-input .form-control {
  height: 40px !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-size: 15px !important;
}

.table-phone-input .react-phone-input:focus {
  box-shadow: none !important;
  border: 2px solid var(--portal-theme-primary) !important;
}

.table-phone-input .react-tel-input .flag-dropdown {
  border: none !important;
}

.table-phone-input .react-tel-input .selected-flag {
  border: none !important;
}

.table-phone-input .react-tel-input .selected-flag .flag {
  left: 12px;
}

.react-phone-input,
.react-tel-input .country-list .search-box {
  width: 100% !important;
  background-color: transparent !important;
  color: #cacaca !important;
  border: 1px solid var(--input-border-color) !important;
}

.react-phone-input:focus {
  box-shadow: none !important;
  border: 1px solid var(--input-border-color) !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

.react-phone-input,
.react-tel-input .country-list .search-box {
  width: 100% !important;
  background-color: transparent !important;
  color: #cacaca !important;
  border: 1px solid var(--input-border-color) !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: rgba(246, 189, 75, 0.16) !important;
}

.react-tel-input .country-list .search-box {
  max-width: 94% !important;
  height: 34px;
}
.country-list,
.react-tel-input .country-list .search {
  background-color: #1d1c1d !important;
}

.react-tel-input .country-list .country:hover {
  background-color: rgba(246, 189, 75, 0.16) !important;
}

.quill-text-editor {
  background-color: #fff;
  color: #000;
}

.quill-text-editor .ql-editor {
  font-size: 16px;
}

.quill-text-editor .quill {
  height: 250px;
  position: relative;
}
.quill-text-editor .ql-snow .ql-tooltip {
  left: 10px !important;
}

.ql-editor a {
  color: #007bff;
}

.ql-syntax {
  color: #000;
}

.new_message_text {
  color: var(--portal-theme-primary);
}
.mobile_banner_image {
  height: 100px;
}

.answer-statement-wh {
  padding: 10px;
}
/* 
.answer-statement-wh .MuiFormControlLabel-label {
  line-height: 1.7 !important;
} */

.answer-statement-wh:hover {
  background-color: var(--portal-theme-secondary);
}

.answer-statement-wh .MuiRadio-colorPrimary {
  padding: 0 8px !important;
}

.autocomplete-dropdown-container {
  position: absolute;
  top: 50px;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  background-color: var(--sidebars-background-color) !important;
}

.suggestion-item {
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 1px solid #000;
  border: 1px solid #c9bdbd;
  display: flex;
  background-color: var(--sidebars-background-color);
}

.suggestion-item--active {
  display: flex;
  background-color: #ccc;
  background-color: var(--portal-theme-secondary);
}

.suggestion-item span,
.suggestion-item--active span {
  font-size: 13px !important;
  margin-left: 1px;
  font-family: "NunitoSans";
  padding: 7px 0;
  display: block;
  cursor: pointer;
}

.suggestion-item b,
.suggestion-item--active b {
  font-size: 13px;
  padding: 7px 0px;
  padding-left: 7px;
  font-family: "NunitoSans";
  display: block;
  word-wrap: nowrap !important;
}

.suggestion-item .fa-location-dot,
.suggestion-item--active .fa-location-dot {
  display: block;
  padding-top: 10px;
  font-size: 13px;
  margin-left: 7px;
}

.autocomplete-input {
  width: 100%;
  padding: 8px;
  background-color: transparent;
  border: 1px solid var(--input-border-color);
  color: #fff;
  border-radius: 8px;
  outline: none;
}

.autocomplete-input:focus-visible {
  border: 2px solid var(--portal-theme-primary);
}

.autocomplete-input::placeholder {
  color: #fff;
}

.disabled-address {
  width: 100%;
  border: 1px solid var(--input-border-color);
  padding: 8px;
  border-radius: 8px;
  color: #919eab;
  cursor: not-allowed;
}

.product-old-price {
  text-decoration: line-through;
  margin-left: 10px;
}

.custome-radio-label.disabled {
  color: #919eab;
  cursor: not-allowed;
}

.border-color {
  border: 1px solid var(--input-border-color);
}

.address-heading {
  color: var(--portal-theme-primary);
}

.event-slider-card {
  background-color: var(--background-secondary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-slider-card .event-image {
  width: 60%;
  height: 100%;
}

.event-slider-card .event-image img {
  height: 160px;
  width: 170px;
  object-fit: contain;
}

@keyframes changeColor {
  0% {
    color: white;
  }
  50% {
    color: var(--portal-theme-primary);
  }
  100% {
    color: white;
  }
}

.color-changing-text {
  animation: changeColor 2s infinite alternate; /* added 'alternate' to reverse the animation direction after each iteration */
}

@keyframes moveBorder {
  0% {
    box-shadow: 0 0 0 1 var(--portal-theme-primary);
  }
  50% {
    box-shadow: 0 0 0 2px var(--portal-theme-primary); /* Adjust the spread radius to control the movement */
  }
  100% {
    box-shadow: 0 0 0 1 var(--portal-theme-primary);
  }
}

.border-changing-text {
  animation: moveBorder 2s infinite alternate; /* added 'alternate' to reverse the animation direction after each iteration */
}

.colored-user-shadow {
  text-shadow: 2px 0 3px rgba(255, 235, 59, 0.9),
    -2px 0 3px rgba(255, 235, 59, 0.9), 0 2px 3px rgba(255, 235, 59, 0.9),
    0 -2px 3px rgba(255, 235, 59, 0.9);
}

@keyframes shadowAnimation {
  0% {
    text-shadow: 2px 0 1px rgba(255, 235, 59, 0.9),
      -2px 0 3px rgba(255, 235, 59, 0.9), 0 2px 3px rgba(255, 235, 59, 0.9),
      0 -2px 3px rgba(255, 235, 59, 0.9);
  }
  50% {
    text-shadow: 3px 0 5px rgba(255, 235, 59, 0.9),
      -3px 0 5px rgba(255, 235, 59, 0.9), 0 3px 5px rgba(255, 235, 59, 0.9),
      0 -3px 5px rgba(255, 235, 59, 0.9);
  }
  100% {
    text-shadow: 2px 0 1px rgba(255, 235, 59, 0.9),
      -2px 0 3px rgba(255, 235, 59, 0.9), 0 2px 3px rgba(255, 235, 59, 0.9),
      0 -2px 3px rgba(255, 235, 59, 0.9);
  }
}

.colored-user-shadow-animate {
  animation: shadowAnimation 2s infinite;
}

.colored-user {
  background-color: var(--portal-theme-secondary);
  /* border: 1px solid var(--portal-theme-primary); */
  padding: 4px;
  border-radius: 8px;
}

.view-all-boards {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.text-golden {
  color: var(--portal-theme-primary);
}

.selected-fire-country {
  color: #fff;
}

.mobile-banner-cross {
  align-items: center;
  background-color: red;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 18px;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 18px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.modal-overlay img {
  width: 100%;
}

.congratulations-popup h2 {
  text-align: center;
}

.congratulations-popup img {
  display: inline;
}

.congratulations-popup {
  color: #fff;
}

.glow-reward-feed {
  box-shadow: 0 0 30px var(--portal-theme-primary);
  border: 2px solid var(--portal-theme-primary);
  position: relative;
}

.glow-reward-feed .gif-image img,
.post-detail-box .gif-image img {
  position: absolute;
  height: 88%;
  width: 94%;
}

.community-events-slider .slick-prev {
  left: 0px !important;
}

.community-events-slider .slick-next {
  right: 0px !important;
}

@media (max-width: 540px) {
  .main-icon-conatiner {
    padding-right: 0px;
  }
  .input-field {
    width: 100% !important;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }

  .hz-scroll .hz-scroll-left {
    left: 12px !important;
    z-index: 1;
  }
  .hz-scroll .hz-scroll-right {
    right: 12px !important;
    z-index: 1;
  }
}

@media (max-width: 500px) {
  .main-icon-content {
    display: none;
  }
  .line {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .upload-video-update .MuiChip-root svg {
    font-size: 20px;
    margin-left: 3px;
    fill: silver;
  }
  .upload-video-update .MuiChip-root {
    width: 100%;
    cursor: pointer;
    border: hidden;
    background: #262626;
    border-radius: 50px;
  }
}
@media (max-width: 500px) {
  .input-with-profile .input-field {
    font-size: 12px;
    line-height: normal;
    padding-top: 13px;
    padding-bottom: 10px;
  }
}
.chatButton {
  color: var(--portal-theme-primary);
  text-decoration: underline;
}
.align-center {
  align-items: center;
}
.fixed_checkout_button {
  position: fixed;
  right: 30px;
  bottom: 30px;
}
.card-with-background {
  background-color: var(--sidebars-background-color);
  border: none;
}
.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.apple-pay-logo {
  width: 53px;
  height: 18px;
  margin-right: 5px;
  margin-top: 3px;
}

.daily-card {
  background-color: var(--background-secondary-color);
  border-radius: 10px;
  padding: 15px;
}

.card-of-day {
  color: #fff;
  width: 400px;
  max-width: 100%;
  text-align: center;
}

.cards-scroll {
  display: flex;
  overflow-x: scroll;
  padding: 20px;
  padding-top: 40px;
}

.card-image {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  margin-right: -255px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
}

/* .card-image img{
  height: 377px;
  width: 256px;
} */

.day-full-card img {
  animation: rotateAnimation 1s linear infinite;
}

@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg); /* Start position */
  }
  100% {
    transform: rotateY(180deg); /* End position */
  }
}

.card-image.selected-card {
  margin-top: -40px;
}

.card-of-day .main-image {
  width: 64%;
  margin: auto;
}

.card-of-day .card-ana {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: bisque;
  width: 16rem;
  height: fit-content;
  min-height: 400px;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.card-of-day .card-ana img {
  margin-top: -20%;
  width: 100%;
  border-radius: 20px;
}

.card-of-day .card-ana h2 {
  margin: 0;
  margin-top: 1rem;
}

.card-of-day .card-ana p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.card-of-day .card-ana .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pick-card-absolute {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, 0%);
  white-space: nowrap;
}

.general-popup-model_update {
  background-color: var(--sidebars-background-color) !important;
  min-width: 650px;
}

.bottom-heading {
  color: var(--portal-theme-primary);
  text-transform: capitalize;
  font-weight: 700;
}
.paragraph-content {
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 5px;
}

.join-today-btn {
  background-color: var(--portal-theme-primary);
  background-color: var(--portal-theme-primary);
  border: none;
  border-radius: 7px;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  margin-top: 80px;
  outline: none;
  padding: 4px 16px;
}

.pick-card-popup {
  height: 400px;
  width: 400px;
  max-width: 90%;
  margin: auto;
}
.graphics-links-height {
  height: 230px;
  overflow: auto;
}
.graphics-links-height-dev {
  height: 375px;
  overflow: auto;
}

.ms-section-heading h3 {
  color: var(--portal-theme-primary);
}

.mission-control-section h3 {
  font-weight: 500;
}

.mission-control-card.bordered {
  border: 2px solid var(--portal-theme-primary);
}

.mission-control-card .current-level {
  color: var(--portal-theme-primary);
  opacity: 1;
}

.mission-control-card {
  background-color: var(--sidebars-background-color);
  border-radius: 16px;
  padding: 16px 0;
  text-align: center;
}

.mission-control-card hr,
.members-leader-board hr {
  margin: 10px 0;
  background-color: var(--portal-theme-primary);
}

.mission-control-card h6 {
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  margin: 0;
}

.mission-control-card img {
  border-radius: 50%;
  display: inline;
  /* height: 40px; */
  margin-bottom: 10px;
  width: 40px;
}

.challenges-card {
  height: 190px;
  overflow: auto;
}

.badges-history-icon {
  height: 50px !important;
  width: 50px !important;
}

@keyframes moveBorder {
  0% {
    scale: 1;
  }
  100% {
    scale: 1.1;
  }
}

.current-level {
  animation: moveBorder 1s infinite alternate; /* added 'alternate' to reverse the animation direction after each iteration */
}

@keyframes nextLevelOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.next-level-arrow {
  animation: nextLevelOpacity 0.5s infinite alternate; /* added 'alternate' to reverse the animation direction after each iteration */
}

.streak_text span {
  font-size: 25px;
  font-weight: 600;
}

.streak-performance-card {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  padding: 10px;
}

.streak_text p {
  font-size: 12px !important;
  line-height: 14px;
  margin: 0;
  opacity: 0.8;
}

.streak_text .coins-counts p {
  font-size: 14px !important;
}

.streak_text img {
  width: 25px !important;
  display: inline;
}

.streak-performance-card img {
  display: inline;
  width: 35px;
  margin-right: 10px;
}

.coins-slider-outer {
  height: 10px;
  width: 100%;
  background-color: var(--portal-theme-secondary);
  border-radius: 10px;
}

.coins-slider-inner {
  height: 100%;
  background-color: var(--portal-theme-primary);
  border-radius: 10px;
}

.total-coins h3 {
  font-size: 40px;
  margin-bottom: 0 !important;
}

.more-coins span {
  color: var(--portal-theme-primary);
}

.more-icon {
  color: var(--portal-theme-primary);
  text-decoration: underline;
  cursor: pointer;
}

.challenge-item {
  text-align: left;
}

.challenge-heading {
  font-size: 18px;
}

.coins-counts {
  font-size: 16px !important;
}

.upgrade-account-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reminder-text {
  margin-top: 16px;
  color: var(--text-secondary-color);
}

.reminder-text i {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 16px;
}

.highlight-text {
  color: var(--portal-theme-primary);
}

.reminder-text .highlight-text {
  cursor: pointer;
}

.gaps-videos-list {
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  max-height: 540px;
  overflow: auto;
}

.gaps-videos-item.locked .gaps-image,
.gaps-videos-item.locked .gaps-description {
  opacity: 0.4;
}

.gaps-videos-item .more-coins {
  margin-top: -45px;
  z-index: 1;
  padding: 0 12px;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
  color: #000 !important;
  padding: 5px;
}

.gaps-videos-item img {
  width: 100%;
  /* height: 120px; */
}

.gaps-videos-item h3 {
  color: var(--portal-theme-primary);
  margin-bottom: 5px !important;
}

.gaps-videos-item .gaps-short-desc {
  color: var(--text-primary-color);
  font-size: 13px;
}

.gaps-videos-item .lock_btn {
  top: 20%;
}

.gaps-lock-image {
  width: 20px !important;
  height: 20px !important;
  opacity: 1 !important;
}

/* .gaps-videos-item {
  display: flex;
}

.gaps-videos-item img {
  width: 100px;
  height: auto;
} */
@media (max-width: 391px) {
  .mobile-btn {
    width: 100%;
    margin-left: 0px !important;
  }
}
@media (max-width: 391px) {
  .mobile-btn {
    width: 100%;
    margin-left: 0px !important;
  }
  .mobile-btn-2 {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
}

.ms-banner-description img {
  width: 100%;
  height: auto !important;
}

.event-schedule-description {
  border: 1px solid var(--input-border-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mc-page-setting-button {
  background: var(--portal-theme-primary);
  color: #fff;
  padding: 10px 20px;
  width: 350px;
  border-radius: 3px;
  border: 1px solid #676767;
  max-width: 100%;
}

.live-event-comment-message span {
  font-size: 14px !important;
  color: var(--text-secondary-color);
}

.chat-history-comment {
  position: relative !important;
  bottom: 0 !important;
  cursor: pointer;
}

.chat-history-comment .comment-like-heart-count {
  font-size: 12px !important;
}

.mui-custom-tabs {
  background-color: var(--sidebars-background-color);
}

.mui-custom-tab-item {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 13px;
}

.mui-custom-tab-item:hover {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}

.mui-custom-tabs .Mui-selected {
  background-color: var(--portal-theme-primary);
  color: #000;
}

.mention-editor {
  position: relative;
  z-index: 99999;
  cursor: text;
}

.mention-editor-box {
  min-height: 50px;
  outline: none;
  max-height: 200px;
  overflow: auto;
  padding-right: 25px;
}

.show-suggestions-names {
  position: absolute;
  top: 23px;
  background-color: var(--sidebars-background-color);
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  max-height: 270px;
  overflow: auto;
  min-width: 250px;
  z-index: 1;
  max-width: 100%;
  border-radius: 5px;
}

.show-suggestions-names.add-border {
  border: 1px solid var(--portal-theme-primary);
}

.show-suggestions-items {
  width: 100%;
  background-color: var(--sidebars-background-color);
  outline: none;
  border: none;
  padding: 6px 12px;
  color: #fff;
  align-items: center;
}

.mention-editor-box:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
  pointer-events: none;
}

.show-suggestions-items:hover {
  background-color: var(--portal-theme-secondary);
}

.show-suggestions-items .suggestion-name {
  white-space: nowrap;
}

.mentioned-name {
  color: var(--portal-theme-primary);
  text-decoration: underline;
  padding: 0 3px 2px 3px;
}

.post-description .mentioned-name {
  padding: 0;
}

.mention-editor-box .mentioned-name {
  background: var(--background-primary-color);
}

.mentioned-user-info {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-primary-color);
  padding: 16px;
  z-index: 1;
  text-align: center;
  border-radius: 5px;
}

.comment-editor {
  font-family: Montserrat, sans-serif;
  border-radius: 8px;
  padding: 16.5px 14px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #fff;
  z-index: 1;
}

.comment-editor .mention-editor-box {
  height: 70px;
  overflow: auto;
  padding-right: 0;
}

.comment-editor .emoji-picker-button-creat-post {
  bottom: -60px;
  left: 3px;
  top: auto;
}

.picker-empty-div {
  width: 40px;
}

.insert-reply-button {
  position: absolute;
  top: 32px;
  right: 12px;
  cursor: pointer;
  z-index: 1;
}

.add-comment-box {
  position: relative;
  z-index: 0;
}

.profile-image-mentioned-user {
  display: inline-block;
}

.show-suggestions-items.selected {
  background-color: var(--portal-theme-secondary);
}

.click-able-link {
  overflow-wrap: anywhere;
}

.add-event-comment .comment-submit-button {
  padding: 4px 6px;
}

.mission-control-card.bordered {
  border: 2px solid var(--portal-theme-primary);
}

.mission-control-card .current-level {
  color: var(--portal-theme-primary);
  opacity: 1;
}

.mission-control-card {
  background-color: var(--sidebars-background-color);
  border-radius: 16px;
  padding: 16px 0;
  text-align: center;
}

.mission-control-card hr,
.members-leader-board hr {
  margin: 10px 0;
  background-color: var(--portal-theme-primary);
}

.mission-control-card h6 {
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  margin: 0;
}

.mission-control-card img {
  border-radius: 50%;
  display: inline;
  /* height: 40px; */
  margin-bottom: 10px;
  width: 40px;
}
.upgrade-account-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gaps-videos-item .more-coins {
  margin-top: -45px;
  z-index: 1;
  padding: 0 12px;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
  color: #000 !important;
  padding: 5px;
}
.more-coins span {
  color: #ffd889;
}
.coins-slider-outer {
  height: 10px;
  width: 100%;
  background-color: var(--portal-theme-secondary);
  border-radius: 10px;
}

.coins-slider-inner {
  height: 100%;
  background-color: #ffd889;
  border-radius: 10px;
}
.total-coins h3 {
  font-size: 40px;
  margin-bottom: 0 !important;
}

.more-coins span {
  color: #ffd889;
}

.more-icon {
  color: var(--portal-theme-primary);
  text-decoration: underline;
  cursor: pointer;
}

.challenges-card {
  height: 190px;
  overflow: auto;
}
.challenge-item {
  text-align: left;
}
.colored-user {
  background-color: var(--portal-theme-secondary);
  /* border: 1px solid var(--portal-theme-primary); */
  padding: 4px;
  border-radius: 8px;
}
.border-changing-text {
  animation: moveBorder 2s infinite alternate; /* added 'alternate' to reverse the animation direction after each iteration */
}

.view-all-boards {
  color: var(--portal-theme-primary);
  cursor: pointer;
}
.streak-performance-card {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  padding: 10px;
}
.streak-performance-card img {
  display: inline;
  width: 35px;
  margin-right: 10px;
}
.streak_text span {
  font-size: 25px;
  font-weight: 600;
}
.streak_text p {
  font-size: 12px !important;
  line-height: 14px;
  margin: 0;
  opacity: 0.8;
}

.streak_text .coins-counts p {
  font-size: 14px !important;
}

.streak_text img {
  width: 25px !important;
  display: inline;
}

.reminder-text {
  margin-top: 16px;
  color: var(--text-secondary-color);
}

.reminder-text i {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 16px;
}

.highlight-text {
  color: var(--portal-theme-primary);
}

.reminder-text .highlight-text {
  cursor: pointer;
}

.road-map-selected-image-new {
  display: inline-block;
  width: 140px;
  text-align: center;
}
.road-map-selected-image-new img {
  width: auto;
  height: 40px;
  display: inline;
}
.road-map-icon-new .title {
  margin: 5px 0 2px;
  color: #ffffff;
}

.road-map-icon-new {
  color: var(--text-secondary-color);
  font-size: 13px;
}
.coins-counts {
  font-size: 16px !important;
}
.challenge-heading {
  font-size: 18px;
}

.info-image {
  filter: invert(55%) sepia(50%) saturate(1500%) hue-rotate(20deg);
  width: 20px;
  display: inline-block;
  cursor: pointer;
}
.cross-iconss {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--portal-theme-primary);
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.inside-accordion-questions .MuiAccordion-gutters {
  border: 1px solid var(--portal-theme-secondary);
}

.close-popup {
  position: absolute;
  right: -5px;
  top: -5px;
  height: 35px;
  width: 35px;
  background-color: var(--portal-theme-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
}

.close_plan_popup_description {
  color: #fff;
}

.total-earning-history {
  color: var(--portal-theme-primary);
}

.earning-description {
  color: lightgray;
  font-size: 15px;
}

.theme-color {
  color: var(--portal-theme-primary);
}
.create-poll-box .cross-icon {
  background-color: transparent !important;
  right: 0% !important;
}

.password_radio span {
  color: white;
}

.password_input .radio_button svg,
.password_radio svg {
  color: var(--icons-color-allover);
  fill: var(--icons-color-allover);
}

.password_input label {
  margin-top: -7px;
}

.password_input .css-16zaun8.Mui-focused,
.password_input .css-15ym1z2-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  margin-top: 0px !important;
}

.password_input .radio_button p {
  margin: 10px 0px;
  font-size: 18px;
}

.fw_14 {
  font-size: 14px;
}

.password_input .radio_button span {
  color: white;
}
.date-space {
  padding-right: 27px;
}
.replies-button span {
  color: #f6bd4b !important;
}
.create-poll-box .MuiInputBase-input {
  font-size: 14px;
}

.create-poll-box .cross-icon {
  position: relative;
  top: unset;
}

.create-poll-box .textarea-autosize:focus-visible {
  border: 1px solid var(--portal-theme-primary) !important;
}
.allow-multiple-options .MuiCheckbox-colorPrimary {
  padding: 5px;
}

.allow-multiple-options .MuiFormControlLabel-label {
  font-size: 14px;
}

.feed-poll-option {
  border: 1px solid var(--input-border-color);
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
}

.feed-poll-option .answered {
  background-color: var(--portal-theme-primary);
  border-radius: 4px;
  color: #000;
  font-weight: 500;
}

.feed-poll-option.poll-expired {
  cursor: default;
  opacity: 0.4;
}

.feed-poll-box {
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
}

.view-poll-result {
  border-top: 1px solid var(--portal-theme-secondary);
  cursor: pointer;
  color: var(--portal-theme-primary);
}

.view-poll-result:hover {
  background-color: var(--portal-theme-secondary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.feed-poll-option:last-child {
  margin-bottom: 0 !important;
}

.feed-poll-popup-modal {
  min-height: 200px;
  min-width: 480px;
  width: 480px;
}

.poll-result-heading {
  color: var(--portal-theme-primary);
  font-size: 16px;
}

.poll-statement {
  font-size: 12px;
}

.feed-poll-avatars {
  margin-bottom: 10px;
}

.feed-poll-avatars .MuiAvatar-root {
  width: 16px;
  height: 16px;
  font-size: 12px;
}
.poll-expiry-date {
  color: var(--text-secondary-color);
}

.poll-expires {
  padding-left: 10px;
  margin-bottom: 8px;
  color: gray;
  font-size: 13px;
}

.poll-result-card {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.replies-button span {
  color: #f6bd4b !important;
}

/* .mission-levels start*/
.mission-levels .mission-icon {
  height: 30px;
}
.mission-levels .next-arrow-icon {
  height: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.mission-levels h5 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}

.mission-levels h2 {
  color: #fff;
  margin-bottom: 0;
  margin-right: 10px;
  font-weight: 600;
  color: var(--portal-theme-primary);
}

.mission-levels .completed-missions {
  display: flex;
  justify-content: end;
  align-items: center;
}

.mission-levels .completed-missions span {
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}

.mission-levels .completed-missions:hover {
  text-decoration: underline;
}

.challenge-video-title {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.challenge-video-title h5 {
  color: #fff;
  margin-left: 0;
}

.challenge-video-image {
  border-radius: 10px;
}

.challenge-video-card .level-icons img {
  height: 35px;
  margin-right: 4px;
  max-width: 35px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
}

.challenge-video-card .level-icons h2 {
  font-size: 25px;
}

.challenge-lock-description {
  display: flex;
  align-items: flex-end;
}

.challenge-lock-description p {
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.challenge-lock-description img {
  height: 25px;
}

.mc-tab-description h3 {
  margin-top: 16px;
}

.mission-levels-schedule-tab .card {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mission-levels-schedule-tab h6 {
  margin-bottom: 5px;
}

.mission-levels-schedule-tab .schedule-click {
  cursor: pointer;
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.mission-levels-schedule-tab .locked-component + .schedule-click {
  opacity: 0.3;
  cursor: initial;
}

.mission-levels-schedule-tab .locked-component {
  position: absolute;
  background-color: #80808036;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.mission-levels-schedule-tab .lock-thumbnail-icon {
  height: 20px;
  cursor: pointer;
}

.challenge-video-days-tabs .locked-component img {
  height: auto !important;
  margin-top: 130px;
}

.challenge-video-days-tabs .locked-component {
  align-items: flex-start;
}

.mc-day-input {
  border: 1px solid var(--input-border-color);
  padding: 0px 16px;
  border-radius: 12px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.mc-day-input h6,
.mc-day-input span {
  font-size: 14px;
  margin-bottom: 0;
}

.mc-day-input.disabled h6,
.mc-day-input.disabled span {
  color: gray;
}

.mc-day-input img {
  height: 14px;
  margin-left: 5px;
}

.mc-day-input .tiny-text {
  font-size: 11px;
  color: #9e9c9c;
}

.day-video-card {
  padding: 15px 20px;
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  position: relative;
}

.day-video-card p {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.day-video-card .unlocked,
.day-video-card .locked {
  margin-bottom: 5px;
}

.day-video-card .unlocked span {
  color: gray;
  font-size: 14px;
  margin-left: 5px;
}

.day-video-card .locked span {
  font-size: 14px;
  margin-left: 5px;
}

.mc-video-button-contained {
  background: linear-gradient(180deg, #ebd7aa 0%, #f0c26a 100%);
  width: 88%;
  outline: none;
  border: none;
  padding: 8px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
}

.mc-video-button-outlined {
  width: 88%;
  outline: none;
  border: none;
  padding: 8px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 10px;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
  font-size: 14px;
  position: absolute;
  bottom: 20px;
}

.mc-button-contained,
.challenge-video-days-questions .question-buttons button {
  background: linear-gradient(180deg, #ebd7aa 0%, #f0c26a 100%);
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  white-space: nowrap;
  padding: 8px 20px;
  border: none;
  outline: none;
  text-transform: uppercase;
}

.mc-button-contained:disabled,
.heading-disabled {
  opacity: 0.2;
}

.challenge-video-days-questions .show-btn-disabled {
  display: none;
}

.mc-button-contained.floating-button {
  position: fixed;
  bottom: 50px;
  right: 30px;
  display: inline;
  z-index: 999999999;
}

.mc-button-outlined,
.challenge-video-days-questions .small-contained-button {
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  white-space: nowrap;
  padding: 8px 20px;
  border: 1px solid var(--input-border-color);
  outline: none;
  text-transform: uppercase;
}

.show-lock-icon-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
}

.day-video-card .thumbnail-icon {
  opacity: 0.2;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
}

.day-video-card .video-description {
  margin-bottom: 50px !important;
}

.unlock-video {
  color: #fff;
  position: relative;
}

.unlock-video p {
  color: gray;
}

.unlock-popup-cross-icon {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: -10px;
  top: -10px;
  text-align: center;
  font-size: 19px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99999;
}

.challenge-video-days-tabs .mui-custom-tab-item:hover,
.challenge-video-detail-tabs .mui-custom-tab-item:hover,
.challenge-video-days-title h2,
.challenge-video-days-title svg,
.challenge-video-days-questions svg,
.challenge-video-detail-title h2,
.challenge-video-days-questions .small-contained-button,
.challenge-video-days-questions a,
.congratulation-message span,
.challenge-video-days-questions .star > button,
.unlock-popup-cross-icon,
.day-video-card .locked span,
.mc-day-input span,
.coins-attracted h5,
.day-video-card h6,
.mission-levels-schedule-tab h6,
.schedule-heading h2,
.mission-levels-overview-tab h3,
.allowed-actions-box h2,
.schedule_completed_description h2,
.mission-level-card h3,
.challenge-video-detail-title svg {
  color: var(--portal-theme-primary);
}

.challenge-video-days-tabs .Mui-selected.mui-custom-tab-item,
.challenge-video-detail-tabs .Mui-selected.mui-custom-tab-item {
  background: transparent;
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 14px;
}

.challenge-video-days-tabs .mui-custom-tab-item:hover {
  background-color: transparent;
  color: var(--portal-theme-primary);
}

.challenge-video-days-tabs .MuiTabs-indicator {
  background-color: var(--portal-theme-primary);
}

.challenge-video-days-questions .card {
  border: 1px solid var(--input-border-color);
}

.challenge-video-days-questions .star > button {
  border: 1px solid var(--portal-theme-primary);
}

.questions-top-section {
  padding: 0 12px;
  color: #fff;
  position: relative;
}

.challenge-video-days-questions .self-button {
  display: none;
}

.challenge-video-days-questions .question-buttons {
  display: block !important;
  text-align: end;
}

.congratulation-message {
  text-align: center;
}

.congratulation-message img {
  width: 300px;
  display: inline;
}

.congratulation-message p {
  color: #fff;
  margin-bottom: 5px;
}

.challenge-video-days-questions .row.justify-content-center.pb-5 {
  padding-bottom: 10px !important;
}

.coins-attracted {
  padding-left: 10px;
}

.coins-attracted h5 {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 0;
}

.coins-attracted h6 {
  font-size: 15px;
  margin-bottom: 0;
}

.schedule-heading {
  /* border: 1px solid var(--portal-theme-primary); */
  /* border-width: 1px 1px 0 1px; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  text-align: center;
  background-color: var(--sidebars-background-color);
  font-size: 18px;
}

.mission-levels-overview-tab {
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
  overflow: auto;
}

.mission-levels-overview-tab.not-started {
  opacity: 0.1;
}

.mission-levels-overview-tab p {
  margin-bottom: 5px !important;
}

.mission-levels-overview-tab h3 {
  margin-top: 1rem;
}
.mission-levels-overview-tab ul {
  margin-bottom: 0 !important;
}

.allowed-actions-box {
  background-color: var(--sidebars-background-color);
  margin-top: 16px;
  border-radius: 10px;
  padding: 16px;
  height: 100%;
}

/* mission-challenges end*/
.inside-accordion-questions .MuiAccordion-gutters {
  border: 1px solid var(--portal-theme-secondary);
}

.close-popup {
  position: absolute;
  right: -5px;
  top: -5px;
  height: 35px;
  width: 35px;
  background-color: var(--portal-theme-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
}

.close_plan_popup_description {
  color: #fff;
}

.total-earning-history {
  color: var(--portal-theme-primary);
}

.earning-description {
  color: lightgray;
  font-size: 15px;
}

.theme-color {
  color: var(--portal-theme-primary);
}
.mission-level-heading h2,
.mission-level-heading img {
  display: inline !important;
}

.mission-level-heading h2 {
  margin-bottom: 16px !important;
}

.mission-level-heading img {
  height: 30px;
  width: 30px;
  margin-top: -10px !important;
}

.required-asterisk {
  color: red;
  font-size: 18px;
}

.save-icon {
  color: var(--portal-theme-primary);
  font-size: 18px;
}

.action-checkbox {
  height: 16px;
  width: 16px;
  border: 2px solid var(--portal-theme-primary);
  border-radius: 3px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-checkbox-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  background: #2b2929;
  padding: 7px 11px;
  border-radius: 6px;
}
.action-checkbox-box-new {
  display: flex;
  cursor: pointer;
  align-items: center;
  background: #2b2929;
  padding: 7px 11px;
  border-radius: 6px;
  justify-content: space-between;
}

.action-checkbox-box-new.disabled {
  opacity: 0.3;
}

.action-checkbox-box.disabled {
  opacity: 0.3;
}

.action-checkbox.checked {
  background-color: var(--portal-theme-primary);
}

.action-checkbox.checked i {
  color: #000;
}

.checkbox-label {
  font-size: 15px;
}

.challenge-video-days-questions .questions_top_description {
  color: #fff;
}

.challenge-video-days-questions-modal {
  min-height: 200px;
  min-width: 510px;
}

.mission-level-card {
  border-radius: 16px;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.mission-level-card > img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.start-mission-note {
  background-color: #282728;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
}
.start-mission-note {
  background-color: #282728;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
}

.unlock-date-note {
  background-color: #282728;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.level-not-match {
  background-color: #282728;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
}

.start-mission-note .note-label {
  font-weight: bold;
  color: var(--portal-theme-primary);
}

.mission-days {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--portal-theme-primary);
  color: #000;
  padding: 2px 20px;
  font-weight: 500;
  border-top-right-radius: 16px;
}

@keyframes borderPulse {
  0% {
    border-color: var(--portal-theme-primary);
    box-shadow: 0 0 2px var(--portal-theme-primary);
  }
  50% {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 5px var(--portal-theme-primary);
  }
  100% {
    border-color: var(--portal-theme-primary);
    box-shadow: 0 0 2px var(--portal-theme-primary);
  }
}

.in-progress-schedule {
  /* border: 1px solid var(--portal-theme-primary) !important; */
  animation: borderPulse 2s infinite;
  transition: all 0.3s ease-in-out;
  padding: 10px; /* Optional for content spacing */
  border-radius: 5px; /* Optional for rounded corners */
}

/* .mission-levels end*/

.inside-accordion-questions .MuiAccordion-gutters {
  border: 1px solid var(--portal-theme-secondary);
}

.close-popup {
  position: absolute;
  right: -5px;
  top: -5px;
  height: 35px;
  width: 35px;
  background-color: var(--portal-theme-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
}

.close_plan_popup_description {
  color: #fff;
}

.total-earning-history {
  color: var(--portal-theme-primary);
}

.earning-description {
  color: lightgray;
  font-size: 15px;
}

.theme-color {
  color: var(--portal-theme-primary);
}
.create-poll-box .cross-icon {
  background-color: transparent !important;
  right: 0% !important;
}

.password_radio span {
  color: white;
}

.password_input .radio_button svg,
.password_radio svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.password_input label {
  margin-top: -7px;
}

.password_input .css-16zaun8.Mui-focused,
.password_input .css-15ym1z2-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  margin-top: 0px !important;
}

.password_input .radio_button p {
  margin: 10px 0px;
  font-size: 18px;
}

.fw_14 {
  font-size: 14px;
}

.password_input .radio_button span {
  color: white;
}
.date-space {
  padding-right: 27px;
}
.replies-button span {
  color: var(--portal-theme-primary) !important;
}
.create-poll-box .MuiInputBase-input {
  font-size: 14px;
}

.create-poll-box .cross-icon {
  position: relative;
  top: unset;
}

.create-poll-box .textarea-autosize:focus-visible {
  border: 1px solid var(--portal-theme-primary) !important;
}
.allow-multiple-options .MuiCheckbox-colorPrimary {
  padding: 5px;
}

.allow-multiple-options .MuiFormControlLabel-label {
  font-size: 14px;
}

.feed-poll-option {
  border: 1px solid var(--input-border-color);
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
}

.feed-poll-option .answered {
  background-color: var(--portal-theme-primary);
  border-radius: 4px;
  color: #000;
  font-weight: 500;
}

.feed-poll-option.poll-expired {
  cursor: default;
  opacity: 0.4;
}

.feed-poll-box {
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
}

.view-poll-result {
  border-top: 1px solid var(--portal-theme-secondary);
  cursor: pointer;
  color: var(--portal-theme-primary);
}

.view-poll-result:hover {
  background-color: var(--portal-theme-secondary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.feed-poll-option:last-child {
  margin-bottom: 0 !important;
}

.feed-poll-popup-modal {
  min-height: 200px;
  min-width: 480px;
  width: 480px;
}

.poll-result-heading {
  color: var(--portal-theme-primary);
  font-size: 16px;
}

.poll-statement {
  font-size: 12px;
}

.feed-poll-avatars {
  margin-bottom: 10px;
}

.feed-poll-avatars .MuiAvatar-root {
  width: 16px;
  height: 16px;
  font-size: 12px;
}

.poll-expiry-date {
  color: var(--text-secondary-color);
}

#video_detail_image_box img {
  max-width: 100%;
}

.poll-expires {
  padding-left: 10px;
  margin-bottom: 8px;
  color: gray;
  font-size: 13px;
}

.poll-result-card {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.comment-editor .mention-editor-box {
  padding-right: 20px;
}

@media (max-width: 571px) {
  .feed-poll-popup-modal {
    min-width: unset;
  }
}

#chart {
  /* justify-content: center;
  display: flex;
  align-items: center; */
  width: 100;
}
.apexcharts-toolbar {
  display: none !important;
}
.actions_heading {
  font-size: 20px;
  color: white;
}

.mission-detail .MuiCalendarPicker-root {
  border-radius: 4px;
}

.mission-detail .MuiDialogActions-root {
  display: none;
}
.mission-detail .MuiPickerStaticWrapper-content {
  width: 100% !important;
  min-width: 100%;
}

.totalDay {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #3f3f3f;
}
.getCoins {
  text-align: end;
  font-size: 11px;
  color: #ffd889;
}

.dashboard-progress-icon {
  color: var(--portal-theme-primary) 50; /* Color for the filled portion (50%) */
}

.dashboard-progress-icon .MuiCircularProgress-circle {
  stroke: var(
    --portal-theme-primary
  ); /* Gray color for the remaining portion */
}

.streak-container {
  padding: 31px 20px !important;
}

.missionIcons {
  position: absolute;
  right: 0%;
  top: 4px;
}

.detail-container {
  /* margin-top: 10px; */
  width: 100%;
}
.detail-container h3 {
  color: #ffd889;
}
.fix-height-issue {
  overflow-x: auto;
  height: 250px;
}

.mission-levels-schedule-tab .lock-thumbnail-icon-new {
  height: 67px;
  cursor: pointer;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scrollable-slider {
  scroll-behavior: smooth;
}

.scrollable-slider::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.scrollable-slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 16px;
  width: 26px;
  background-size: 100%, 100%;
  border-radius: 50%;

  background-color: var(--portal-theme-secondary);
}

.slider-icon .css-vubbuv {
  fill: black;
}
.main-container {
  display: flex !important;
}
.image-container {
  width: 270px;
}
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .detail-container {
    margin-left: 0;
  }
  .detail-container p,
  .detail-container h3 {
    margin-left: 0px !important;
  }
  .image-container {
    width: 100%;
  }
  .missionIcons {
    display: none;
  }
  .hide-in-mobile {
    display: none;
  }
}
.emoji-picker-popup {
  width: fit-content;
}

.fw-16 {
  font-size: 16px !important;
}
.schadule-card {
  transition: all 0.5s;
  transform: scale(1);
}
.schadule-card:hover {
  transform: scale(0.95);
  box-shadow: 0 0 5px #f0c26a;
}
.question-answer .MuiFormControlLabel-label {
  font-size: 14px !important;
}
.cross-for-mission {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: 19px;
  top: 6px;
  color: var(--portal-theme-primary);
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.story-container {
  padding: 20px;
}

.story-cards {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
  white-space: nowrap;
}

.story-card {
  width: 200px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.story-card:hover {
  transform: scale(1.05);
}

.story-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.story-title {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 80%;
  max-width: 600px;
}

.story-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.unlock-color {
  color: #ffd889;
}
.view-detail-button {
  color: #ffd889;
  text-decoration: underline;
  cursor: pointer;
}

.unlockIconNew {
  z-index: 99;
  width: 16px;
  height: 18px;
  cursor: pointer;
}
.schelduleListImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #414141;
  margin: auto;
}
.currentSchedules {
  border: 1px solid #ffd889 !important;
}
.completeImage {
  background: green;
  display: inline-block;
  padding: 3px;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 5px;
  z-index: 99;
}
.completeImage img {
  width: 12px;
  filter: brightness(0) invert(1);
}
.centerAlign_new {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  /* right: -10%; */
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 60%; */
  justify-content: center;
  align-items: center;
  color: #ffd889;
  border: 1px solid #ffd889;
}

.start-mission-note-new {
  background-color: #b01e2820;
  border-left: 4px solid #b01e28;
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  position: relative;
}
.start-mission-note-new .note-label {
  font-weight: bold;
  color: var(--portal-theme-primary);
}
.main-container-badges {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 24%;
  background-color: #2b2929;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .main-container-badges {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    background-color: #2b2929;
    border-radius: 4px;
  }
}
.missedImage {
  background: red;
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  right: 17px;
  top: 7px;
  z-index: 99;
}
.missedImage img {
  width: 7px;
  filter: brightness(0) invert(1);
}

.start-mission-note-new p {
  margin-bottom: 0px;
}
.reward-details-popup {
  cursor: pointer;
  text-decoration: underline;
  color: #ffd889;
  border: 1px solid;
  border-radius: 50%;
  padding: 3px;
}
.reward-details-popup img {
  width: 30px;
  filter: brightness(0) saturate(100%) invert(87%) sepia(22%) saturate(1090%)
    hue-rotate(325deg) brightness(103%) contrast(101%);
}

.title-small {
  margin-top: 18px;
  margin-left: 20px;
  text-transform: capitalize;
  text-align: center;
  color: #ffd889;
  font-size: 20px;
}
.question-text p {
  margin-bottom: 0px !important;
  color: white;
}

.box-1 {
  display: flex;
  height: 13px;
  width: 13px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box-1-color {
}
.box-2-color {
  border: 1px solid #5ab834;
  background: #5ab834;
}
.box-3-color {
  border: 1px solid #a020f0;
  background: #a020f0;
}
.box-4-color {
  border: 1px solid #0000ff;
  background: #0000ff;
}
.box-5-color {
  border: 1px solid #c70039;
  background: #c70039;
}
.mission-calender .MuiCalendarPicker-root {
  background-color: #1d1c1d !important;
}

.journey-item {
  /* border: 1px solid var(--portal-theme-secondary); */
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
  background-color: #252424;
  padding-top: 13px;
  padding-bottom: 13px;
}

.journey-item img {
  max-height: 70px;
  border-radius: 3px;
  width: 100%;
}

.lock-icon-journey {
  width: 10px !important;
  position: absolute;
  right: 3%;
  z-index: 99;
  top: 31%;
}

.arrow_box {
  background-color: #252424;
  color: white;
  padding: 10px;

  z-index: 9999999;
  position: relative;
}

.question-text {
  color: #ffd889;
}

.action-main-container {
  cursor: pointer;
  background: #2b2929;
  padding: 7px 11px;
  border-radius: 6px;
}

.question-container p {
  margin-bottom: 0px !important;
}
.detailed-description-container p {
  margin-bottom: 0px !important;
}

.indication-text {
  border: 1px solid #282828;
  padding: 4px 12px;
  border-radius: 21px;
  background: #202020;
  display: flex;
  align-items: center;
  color: #b7b7b7;
  font-size: 12px;
}

.unlockIconNewDetail {
  z-index: 99;
  width: 19px;
  height: 24px;
  cursor: pointer;
}
.badge-new-mission {
  font-size: 12px;
  color: #a6a4a4;
  line-height: 16px;
  margin-top: 3px;
}
.popular_title {
  width: 0px;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 25px solid #f0c26a;
  position: absolute;
  right: -17px;
  top: -4px;
  color: #000;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
}
.lock-icon-journey {
  transform: rotate(315deg);
  margin-top: 8px;

  margin-right: -3px;
}

.lock-icon-journey .css-vubbuv {
  fill: black !important;
}
.detailed_description-container p {
  margin-bottom: 5px !important;
}

.lock-icon-journey .css-vubbuv {
  fill: black !important;
}

.page-box {
  width: 70%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.note {
  background-color: #282728;
  border-left: 4px solid #ffd889;
  border-left: 4px solid rgb(246, 189, 75);
  border-radius: 7px;
  font-style: italic;
  padding: 10px;
}
.thank-button-new {
  font-size: 15px;
  color: #000608;
  background: #b59d4e;
  text-decoration: none;
  padding: 9px 1px;
  font-weight: 600;
  border-radius: 30px;
  font-family: Montserrat;
  outline: none;
  border: none;
  width: 44%;
  margin: auto;
}
.banner-image {
  margin: auto;
}
.tanks-text {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.mission-control-card-new h2 {
  font-size: 20px;
}
.poll-question-item {
  border: 1px solid var(--portal-theme-secondary);
  background-color: #262626;
  padding: 10px 12px 8px 12px;
  border-radius: 5px;
  width: 100%;
}

.poll-question-item-post {
  background-color: #262626;
  padding: 12px;
  border-radius: 8px;
}

.poll-question-item-post .question-statement,
.poll-question-statement {
  color: var(--portal-theme-primary);
  font-size: 15px;
}

.allow-multiple-options .MuiCheckbox-colorPrimary {
  padding: 4px;
  margin-left: 4px;
}

.allow-multiple-options .MuiFormControlLabel-label,
.poll-question-item-post .MuiFormControlLabel-label {
  font-size: 14px !important;
}

.poll-question-item-post .MuiCheckbox-colorPrimary svg,
.poll-question-item-post .MuiRadio-colorPrimary svg {
  width: 22px !important;
  height: 22px !important;
}

.poll-progress-bar {
  flex: 1;
  height: 7px;
  background-color: var(--portal-theme-secondary);
  border-radius: 15px;
}

.poll-progress-bar .selected-progress-bar {
  background-color: var(--portal-theme-primary);
  height: 100%;
  border-radius: 15px;
}

.poll-progress-bar .selected-progress-bar.poll-expired {
  opacity: 0.4;
}
.card-vedio {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s, border 0.3s;
  background-color: #262626;
  height: 100%;
}
.card-vedio:hover {
  transform: translateY(-5px);
  box-shadow: var(--portal-theme-primary) 50 0px 0px 0px 1px;
  border: 1px solid var(--portal-theme-primary);
  height: 100%;
}

.card-vedio-highlight {
  box-shadow: var(--portal-theme-primary) 50 0px 0px 0px 1px;
  border: 1px solid var(--portal-theme-primary);
}

.warningImage {
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  right: 14px;
  top: 1px;
  z-index: 99;
}
.warningImage img {
  width: 24px;
}
.disabled-audio {
  pointer-events: none;
  opacity: 0.5; /* Optional to show a visual cue */
}
.textarea-autosize-general {
  width: 100%;
  border-radius: 11px;
  background: #141717;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  color: #fff;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}

.textarea-autosize-general:focus-visible {
  outline: none;
  border: 2px solid var(--portal-theme-primary) !important;
}

.dynamite-diary-page .player iframe {
  border-radius: 15px !important;
}

.statement-dots {
  width: 5px;
  height: 5px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
  margin-right: 10px;
  min-width: 5px;
}

.past-diaries-list h4 {
  color: var(--portal-theme-primary);
}

.total-coins-attracted img {
  width: 40px;
  height: 40px;
}

.total-coins-attracted {
  font-size: 13px !important;
  font-weight: bold;
}

.total-coins-attracted .coint-title {
  color: var(--portal-theme-primary);
}

.diary-coins-count img {
  height: 20px;
  width: 20px;
  display: inline;
  margin-right: 5px;
}

.past-diaries-list-container .fa-calendar-days {
  font-size: 18px;
  color: var(--portal-theme-primary);
  width: 20px;
}
.disabled-mission {
  opacity: 0.4;
}

.dynamite-diary-edit-options {
  position: absolute;
  top: 0px;
  right: 10px;
}

.past-diaries-lis .intention-statement {
  font-size: 14px !important;
}

.dashboard_description h2 {
  color: var(--portal-theme-primary) !important;
  display: block !important;
  margin-bottom: 10px !important;
}

.program-list-card .mui_gray_description {
  position: absolute;
  bottom: 16px;
}

.program-list-card .mui-custom-card-description {
  margin-bottom: 25px !important;
}
.view-point-small {
  display: none;
}
.view-point-large {
  display: block;
}

@media (max-width: 768px) {
  .view-point-small {
    display: block;
  }
  .view-point-large {
    display: none;
  }
}

.general-questions-top-description textarea:disabled {
  color: #919eab !important;
}
.replay-details .card-title {
  font-size: 14px;
  color: white;
  margin-bottom: 3px !important;
}

@media (max-width: 768px) {
  .mobile-button-container-for-live-event {
    text-align: end;
    width: 100%;
  }

  .general-live-chat-list .affirmation-dots .remove-border {
    top: -2px;
    right: 2px;
    width: 20px;
    height: 20px;
  }
  .general-live-chat-list .affirmation-dots .remove-border {
    top: -2px;
    right: 2px;
    width: 20px;
    height: 20px;
  }
}

.source-journey {
  color: #000;
  width: 100%;
}

.bg-source-journey {
  background-color: #f9df79;
  padding: 0.7rem !important;
  border-radius: 15px;
}
.bg-info-of-source-journey {
  background-color: #e4cb6d;
  padding: 10px;
  border-radius: 15px;
  min-width: 92px;
}
.info-of-source-journey-text {
  font-weight: 600;
}

.title-source-journey {
  font-weight: 600;
}
.bg-coins-leaderboard-data {
  background-color: #1d1c1d;
}

.feed-image {
  height: 100%;
}

.source-journey {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.source-journey .container {
  flex-grow: 1;
}

.bg-today-actions {
  /* background-color: #c3a1f9; */
  background-color: #1d1c1d;
  color: #fff;
  padding: 0.7rem !important;
  height: 100%;
  border-radius: 15px;
  min-width: 158px;
  width: 95%;
}
.bg-reward-badges {
  /* background-color: #a3d9f7; */
  background-color: #1d1c1d;
  color: #fff;
  height: 100%;
  border-radius: 15px;
  /* padding: 0.7rem !important; */
}
.bg-reward-badges img {
  max-width: 30px;
  max-height: 30px;
  border-radius: 7px;
}

/*  */
.mission {
  color: #000;
}
.bg-mission {
  background-color: #1c1c1c;
  border-radius: 15px;
}
.bg-info-of-mission {
  background-color: #202023;
  padding: 10px;
  border-radius: 15px;
  min-width: 32%;
}
.info-of-mission-text {
  font-weight: 500;
  text-transform: capitalize;
}

.title-mission {
  color: #eec977;
}
.bg-top-session {
  /* background: #201f1f!important; */
}
.bg-session {
  background: #18181b !important;
  height: 100%;
}
.text-session {
  color: #e8c375 !important;
}

.title-mission {
  color: #eec977;
}
.bg-top-session {
  /* background: #201f1f!important; */
}
.bg-session {
  background: #18181b !important;
  height: 100%;
}
.text-session {
  color: #e8c375 !important;
}

.coins-content {
  display: flex;
  align-items: center;
  color: #a3a3a3;
}
.dd-chip {
  height: 26px;
  font-size: 12px;
  cursor: pointer;
}

.upcoming-events-new .card-title {
  font-size: 10px;
  font-weight: 200;
}
.custom-lable {
  font-size: 13px;
  margin-top: 8px;
  display: inline-block;
}

.lock-icon-journey-main {
  transform: rotate(411deg);
  margin-top: 39px;
  margin-right: 6px;
}
.lock-icon-journey-main-container {
  width: 0px;
  height: 0;
  border-left: 38px solid transparent;
  border-right: 27px solid transparent;
  border-bottom: 33px solid #f0c26a;
  position: absolute;
  /* right: -17px; */
  top: -3px;
  color: #000;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-51deg);
  left: -25px;
  z-index: 9;
}
.lock-icon-journey-main-container-locked {
  width: 0px;
  height: 0;
  border-left: 38px solid transparent;
  border-right: 27px solid transparent;
  border-bottom: 33px solid #f0c26a;
  position: absolute;
  /* right: -17px; */
  top: 9px;
  color: #000;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-51deg);
  left: -26px;
  z-index: 9;
}
.lock-icon-journey-main-container-complete {
  width: 0px;
  height: 0;
  border-left: 38px solid transparent;
  border-right: 27px solid transparent;
  border-bottom: 33px solid #009933;
  position: absolute;
  /* right: -17px; */
  top: -3px;
  color: #000;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-51deg);
  left: -25px;
  z-index: 9;
}

.click {
  position: absolute;
  top: 71px;
  left: 73px;

  width: 37px;
  transform: rotate(49deg);
  animation: moveToAndFro 0.9s infinite alternate ease-in-out;
}

@keyframes moveToAndFro {
  0% {
    top: 71px;
    left: 73px;
  }
  100% {
    top: 60px;
    left: 67px;
  }
}

.bg-info-of-mission-2 {
  border-radius: 10px !important;
  top: 61px;
  left: 57px;
}

.lock-icon-journey-main .css-vubbuv {
  fill: #000 !important;
  color: #000 !important;
}

.win-story-card {
  width: 180px;
  flex: none;
}

.win-story-card .schadule-card {
  height: 230px;
  border-radius: 10px;
}

.story-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.win-story-card .schedule-click {
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: flex;
  flex-direction: column;
}

.story-user-box {
  position: absolute;
  top: 10px;
  left: 10px;
}

.story-user-box .MuiAvatar-circular {
  width: 30px;
  height: 30px;
  border: 2px solid rgb(255, 216, 137);
}

.story-user-name-box:first-child {
  left: 0;
  right: 0;
  background-color: #fff;
  bottom: 0;
  color: #000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 50px;
}

.story-user-name-box {
  position: absolute;
  bottom: 4px;
  left: 10px;
  right: 10px;
  z-index: 2;
  color: white;
}

.win-story-card .user-profile-image {
  width: 35px;
  height: 35px;
  border: 2px solid rgb(255, 216, 137);
  position: absolute;
  top: 20px;
  left: 10px;
}

.carousel-control-prev {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block !important;
  width: 20px;
  height: 20px;
  left: 10px;
}

.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  right: 10px;
  display: inline-block !important;
  width: 20px;
  height: 20px;
}

.carousel-control-prev .slider-icon,
.carousel-control-next .slider-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd889;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.new-community-page .win-story-card {
  width: 120px !important;
}

.new-community-page .win-story-card .schadule-card {
  height: 165px !important;
}

.new-community-page .win-story-card .story-user-name {
  color: white;
  font-size: 13px;
}

.story-feed-description {
  position: absolute;
  top: 50px;
  left: 10px;
  color: #ffffffcf;
  line-height: 1.4;
  font-size: 12px;
}

.create-win-post-card-box .story-user-name {
  color: #000 !important;
  text-align: center;
  bottom: 6px;
  position: absolute;
  left: 27px;
}

.win-post-loader-box.win-story-card {
  width: 60px !important;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.win-post-loader-box.win-story-card .MuiCircularProgress-root {
  height: 30px !important;
  width: 30px !important;
}

.create-story-circule-border {
  display: flex;
  justify-content: center;
  background-color: #fff;
  bottom: 30px;
  position: absolute;
  left: 42px;
  padding: 3px;
  border-radius: 50%;
}

.create-story-circule {
  border-radius: 50%;
  background-color: var(--portal-theme-primary);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-story-circule svg {
  color: #000;
  fill: #000;
}

.post-creator.user-info-card .like-profile-image {
  border-radius: 50%;
  padding: 1px;
}

.user-info-card .creator-name svg {
  font-size: 17px;
  color: red;
  fill: red;
  margin-left: 5px;
}

.create-post-title-form .mention-editor {
  background-color: #262626a1;
  padding: 10px;
  border-radius: 10px;
}

.create-post-title-form .mention-editor .mention-editor-box {
  min-height: 80px !important;
}

.mission_dashboard_description h3 {
  color: var(--portal-theme-primary);
}

.medal-img {
  width: 20px !important;
}

.challenge-video-days-questions-modal-new {
  min-width: 450px;
}

.challenge-video-days-questions.container {
  max-width: 100% !important;
}

.transparent-background {
  background-color: transparent !important;
  border-bottom: 1px solid #3a3a3a;
  border-radius: 1px !important;
}
.pointer_hand {
  width: 18px !important;
  margin-left: 10px;
  animation: moveleftAndright 0.9s infinite alternate ease-in-out;
}

@keyframes moveleftAndright {
  0% {
    margin-left: 10px;
  }
  100% {
    margin-left: 3px;
  }
}

.view-all-button {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: #cacaca;
}
.view-all-button:hover {
  color: #f0c26a;
}

.main-heading-leader-boeard {
  border-bottom: 1px solid gray;
  padding-bottom: 8px;
  margin-bottom: 18px;
}

.main-card-container {
  background: #292929;
  border-radius: 8px;
  padding: 5px 10px;
}

.mission-levels-schedule-tab .story-user-name {
  margin-bottom: 5px;
  color: #fff;
  font-size: 14px;
}
.completed-images {
  width: 18px;
  filter: brightness(0) invert(1);
}

.upcomingTitile {
  font-size: 16px;
  font-weight: 500;
}

.upcomingdes {
  font-size: 12px !important;
}

.level-not-match p {
  margin-bottom: 0px;
}

.no-data-image img {
  width: 20% !important;
}
.user-coins {
  text-align: end;
}
.chip-update {
  position: absolute;
  bottom: 15px;
}
.chip-update-new {
  position: static;

  border-radius: 6px;
  height: 35px;
}

.current-badge-color-box {
  border-radius: 50%;
  padding: 1px;
}

.current-badge-color-box .MuiAvatar-root {
  height: 35px;
  width: 35px;
}

.current-badge-color-box .MuiAvatar-root img {
  height: 33px;
  width: 33px;
  border-radius: 50%;
}

.copy-web-app-url svg {
  font-size: 16px;
  fill: var(--portal-theme-primary);
  color: var(--portal-theme-primary);
}

.copy-web-app-url .copy-link {
  /* color: var(--portal-theme-primary); */
  cursor: pointer;
}

.report-feed-popup {
  width: 500px;
  max-width: 100%;
}

.mission-control-description p {
  margin-bottom: 0px;
}

.mission-control-card-new {
  text-align: start;
}

.mission-control-card-new {
  text-align: start;
}

.main-container {
  position: relative;
}

.fix-button-position {
  position: fixed;
  right: 30px;
  bottom: 70px;
}

.under-review-post {
  background-color: #ff484222;
  padding: 8px;
  border-radius: 5px;
  font-style: italic;
}

.under-review-post svg {
  color: #ff4842;
  fill: #ff4842;
  margin-right: 7px;
  font-size: 22px;
}

.profile-cards.main-section {
  min-height: unset;
}

.mission-control-card-new {
  text-align: start;
}

.quest-payment {
  color: white !important;
  font-size: 18px;
}

.img-quest {
  width: 100%;
  height: auto;
  max-height: 135px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}

.fix-mission-paragraph-text p {
  font-size: 14px;
}
.pay-by,
.method-pay-button {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: #000;
  height: 50px;
}

.method-pay-button img {
  height: 35px !important;
}
.pay-by.active,
.method-pay-button.active {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  background: #f0f0f0 !important;
}

.art-payment-card-div .method-pay-button.active {
  background: #b59d4e !important;
}
.quest-plan-description img {
  height: auto !important;
}
.lock-icon-journey-main-container-complete-schedule {
  width: 0px;
  height: 0;
  border-left: 38px solid transparent;
  border-right: 27px solid transparent;
  border-bottom: 33px solid #009933;
  position: absolute;
  right: -19px;
  top: 9px;
  color: #000;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-50deg);
  left: -25px;
  z-index: 9;
}

.quest-plan-heading {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 2px !important;
}
.quest-plan-container {
  align-items: center;
}
.discount-title {
  position: absolute;
  top: -11px;
  left: 18px;
  border: 1px solid #e4c073;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: 9px;
  text-transform: capitalize;
  background-color: #e4c073;
  color: black;
}
.plan-option {
  cursor: pointer;
}
.plan-option .MuiAccordionSummary-content {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  cursor: pointer;
}
.plan-option .MuiAccordionDetails-root {
  padding: 0px 16px 0px;
}
.plan-text p {
  font-size: 14px;
}
.stripe-card-container {
  font-family: "Montserrat", sans-serif !important;
}

.voilent-message {
  color: red !important;
}

.profile-card {
  background-color: #262626;
  border-radius: 10px;
  padding: 4px;
}

.profile-stat-icon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 5px;
}
.profile-card h6 {
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  margin: 0;
}

.cover-image {
  width: 100%;
  margin: auto;
  height: auto;
}
.cover-photo-container {
  border: 1px solid #252525;
  border-radius: 6px;
}
.user-profile-container {
  /* display: flex;
  align-items: center; */
}

.user-profile-data h2 {
  color: #e4c073;
  color: var(--portal-theme-primary);
  font-size: 18px !important;
  margin: 0;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #aeaeae !important;
}
.user-profile-data h6 {
  font-weight: 400 !important;
  margin-bottom: 0;
  opacity: 0.8;
}
.quests-leader-board-image {
  border-radius: 5px;
  height: auto;
  object-fit: cover;
  width: 50px;
}
.quest-leader-board-item {
  background-color: #2c2a2a;
  border-radius: 2px;
  cursor: pointer;
  padding: 5px 3px;
}
.profile-card {
  background-color: #262626;
  padding: 10px;
  border-radius: 10px;
}

.profile-card h6 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
}
.profile-card h3 {
  opacity: 0.8;
}
.user-profile-data h6 {
  font-size: 14px;
}
.main-card {
  background-color: #1d1c1d;
  border-radius: 10px;
}
.profile-sub-info {
  font-size: 14px;
  font-weight: 500;
}
.profile-stat-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--sidebars-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.profile-stat-icon-dd {
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.profile-stat-icon svg {
  font-size: 20px;
}
